import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-4" };
const _hoisted_2 = { class: "text-sm font-semibold" };
const _hoisted_3 = { class: "flex items-center justify-center py-2 text-blue-900 font-bold" };
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "PeggyUsdcDetected",
  setup(__props) {
    const modalStore = useModalStore();
    const isModalOpen = computed(() => modalStore.modals[Modal.USDCDetected]);
    function handleClose() {
      modalStore.closeModal(Modal.USDCDetected);
    }
    return (_ctx, _cache) => {
      const _component_NuxtLink = __nuxt_component_0;
      const _component_AppModal = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "onModal:closed": handleClose
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("trade.usdcLegacyBalanceDetected")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.haveLegacyUSDC")), 1),
            _createVNode(_component_NuxtLink, {
              class: "whitespace-nowrap w-full bg-blue-500 text-black rounded",
              to: { name: "account" }
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("trade.viewUSDC")), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
