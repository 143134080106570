import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-a618e26a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
import {
  BigNumber,
  BigNumberInBase,
  BigNumberInWei
} from "@injectivelabs/utils";
import {
  Change,
  MarketType
} from "@injectivelabs/sdk-ui-ts";
import { DerivativeOrderSide, SpotOrderSide } from "@injectivelabs/sdk-ts";
import {
  BusEvents
} from "@/types";
import { UI_MINIMAL_ABBREVIATION_FLOOR } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Record",
  props: {
    aggregation: {
      type: Number,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    position: {
      required: true,
      type: Number
    },
    record: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    userOrders: {
      required: true,
      type: Array
    }
  },
  emits: ["update:active-position"],
  setup(__props, { expose, emit }) {
    const props = __props;
    const isSpot = props.market.type === MarketType.Spot;
    const element = ref();
    const existsInUserOrders = computed(
      () => props.userOrders.some((price) => {
        if (!props.record.aggregatePrices) {
          return false;
        }
        return props.record.aggregatePrices.includes(price);
      })
    );
    const recordTypeBuy = computed(
      () => props.type === DerivativeOrderSide.Buy || props.type === SpotOrderSide.Buy
    );
    const total = computed(() => new BigNumberInBase(props.record.total || 0));
    const quantity = computed(
      () => isSpot ? new BigNumberInWei(props.record.quantity).toBase(
        props.market.baseToken.decimals
      ) : new BigNumberInBase(props.record.quantity)
    );
    const depthWidth = computed(() => ({
      width: `${props.record.depth}%`
    }));
    const newRecordClass = computed(() => {
      switch (quantityChange.value) {
        case Change.NoChange:
          return "";
        case Change.New:
          return recordTypeBuy.value ? "green-record-bg" : "red-record-bg";
        case Change.Increase:
          return "green-record-bg";
        case Change.Decrease:
          return "red-record-bg";
        default:
          return "";
      }
    });
    const quantityChange = computed(() => {
      const { oldQuantity, quantity: quantity2 } = props.record;
      const oldQuantityBN = new BigNumber(oldQuantity || 0);
      const quantityBN = new BigNumber(quantity2);
      if (oldQuantityBN.isNaN()) {
        return Change.NoChange;
      }
      if (oldQuantityBN.eq(0)) {
        return Change.New;
      }
      if (oldQuantityBN.eq(quantityBN)) {
        return Change.NoChange;
      }
      return oldQuantityBN.gte(quantityBN) ? Change.Decrease : Change.Increase;
    });
    const aggregatedValue = computed(() => {
      const value = new BigNumberInBase(10 ** Math.abs(props.aggregation));
      return props.aggregation < 0 ? value : new BigNumberInBase(1).dividedBy(value);
    });
    const aggregatedPriceInBigNumber = computed(
      () => new BigNumberInBase(props.record.aggregatedPrice || 0)
    );
    function onPriceClick() {
      if (props.record.aggregatedPrice) {
        useEventBus(BusEvents.OrderbookPriceClick).emit({
          isBuy: recordTypeBuy.value,
          price: props.record.aggregatedPrice
        });
      }
    }
    function onSizeClick() {
      useEventBus(BusEvents.OrderbookSizeClick).emit({
        isBuy: recordTypeBuy.value,
        quantity: quantity.value.toFixed()
      });
    }
    function onNotionalClick() {
      useEventBus(BusEvents.OrderbookNotionalClick).emit({
        isBuy: recordTypeBuy.value,
        quantity: quantity.value.toFixed(),
        total: total.value.toFixed()
      });
    }
    function handleMouseEnter() {
      emit("update:active-position", props.position);
    }
    function handleMouseLeave() {
      emit("update:active-position", null);
    }
    expose({
      element
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppNumber = __nuxt_component_1;
      return _openBlock(), _createElementBlock("li", {
        ref_key: "element",
        ref: element,
        class: "flex h-6 items-center last:mb-0 first:mt-0 relative cursor-pointer w-full overflow-hidden",
        onMouseenter: handleMouseEnter,
        onMouseleave: handleMouseLeave
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["size-col", _unref(newRecordClass)])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass(["depth-col", _unref(recordTypeBuy) ? "buys-green-bg" : "sells-red-bg"]),
          style: _normalizeStyle(_unref(depthWidth))
        }, null, 6),
        _createElementVNode("span", {
          class: "w-1/3 text-xs px-2 flex items-center justify-end z-[8]",
          onClick: _withModifiers(onPriceClick, ["stop"])
        }, [
          _unref(existsInUserOrders) ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            name: "arrow",
            "data-cy": "orderbook-record-own-order-icon",
            class: "text-gray-300 transform rotate-180 mr-2 w-2 h-2"
          })) : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: _normalizeClass(["block text-right font-mono", {
              "text-green-500": _unref(recordTypeBuy),
              "text-red-500": !_unref(recordTypeBuy)
            }])
          }, [
            _createVNode(_component_AppNumber, {
              xs: "",
              prefix: _unref(aggregatedValue).gt(__props.record.aggregatedPrice || 0) && _unref(recordTypeBuy) ? "<" : "",
              decimals: __props.aggregation < 0 ? 0 : __props.aggregation,
              number: _unref(aggregatedValue).gt(__props.record.aggregatedPrice || 0) ? _unref(aggregatedValue) : _unref(aggregatedPriceInBigNumber),
              "no-grouping": "",
              "data-cy": "orderbook-record-price-text-content"
            }, null, 8, ["prefix", "decimals", "number"])
          ], 2)
        ], 8, _hoisted_1),
        _createElementVNode("span", {
          class: "w-1/3 text-xs px-2 z-[8]",
          onClick: _withModifiers(onSizeClick, ["stop"])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["block text-right font-mono", {
              "text-red-500": _unref(quantityChange) === _unref(Change).Decrease,
              "text-green-500": _unref(quantityChange) === _unref(Change).Increase
            }])
          }, [
            _createVNode(_component_AppNumber, {
              xs: "",
              decimals: __props.market.quantityDecimals,
              number: _unref(quantity),
              "abbreviation-floor": _unref(UI_MINIMAL_ABBREVIATION_FLOOR),
              "no-grouping": "",
              "data-cy": "orderbook-record-quantity-text-content"
            }, null, 8, ["decimals", "number", "abbreviation-floor"])
          ], 2)
        ], 8, _hoisted_2),
        _createElementVNode("span", {
          class: "w-1/3 text-xs px-2 font-mono text-right z-[8]",
          onClick: _withModifiers(onNotionalClick, ["stop"])
        }, [
          _createVNode(_component_AppNumber, {
            xs: "",
            decimals: __props.market.priceDecimals,
            number: _unref(total),
            "no-grouping": "",
            "data-cy": "orderbook-record-total-text-content"
          }, null, 8, ["decimals", "number"])
        ], 8, _hoisted_3)
      ], 544);
    };
  }
});
