import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Checkbox.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Input/Numeric.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "border-t mt-6" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "block font-semibold text-sm text-gray-200 flex-1",
  "data-cy": "trading-page-advanced-settings-span"
};
const _hoisted_4 = { class: "flex items-stretch" };
const _hoisted_5 = { class: "flex gap-1 my-auto" };
const _hoisted_6 = { class: "flex flex-col flex-1 my-auto gap-1" };
const _hoisted_7 = { class: "text-sm" };
const _hoisted_8 = {
  key: 1,
  class: "flex justify-between"
};
const _hoisted_9 = { class: "text-sm whitespace-nowrap overflow-ellipsis pr-2" };
const _hoisted_10 = { class: "text-sm" };
const _hoisted_11 = { class: "basis-1/5 flex items-center" };
const _hoisted_12 = /* @__PURE__ */ _createElementVNode("span", { class: "lg:hidden" }, " % ", -1);
const _hoisted_13 = { class: "text-sm" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { DEFAULT_SLIPPAGE, MAX_SLIPPAGE } from "@/app/utils/constants";
import { TradeField } from "@/types";
import { tradeErrorMessages } from "@/app/client/utils/validation/trade";
var SlippageDisplayOptions = /* @__PURE__ */ ((SlippageDisplayOptions2) => {
  SlippageDisplayOptions2["NonSelectableDefault"] = "Zero";
  SlippageDisplayOptions2["SlippageInput"] = "Selected";
  SlippageDisplayOptions2["Selectable"] = "Selectable";
  return SlippageDisplayOptions2;
})(SlippageDisplayOptions || {});
export default /* @__PURE__ */ _defineComponent({
  __name: "AdvancedSettings",
  props: {
    isSpot: Boolean,
    isBaseAmount: Boolean,
    isConditionalOrder: Boolean,
    reduceOnlyDisabled: Boolean
  },
  setup(__props) {
    const props = __props;
    const { t } = useLang();
    const formValues = useFormValues();
    const drawerIsOpen = ref(true);
    const slippageSelection = ref("Selectable" /* Selectable */);
    const slippageIsToggleable = ref(true);
    const {
      isConditionalOrder,
      tradingTypeStopMarket,
      tradingTypeLimit: derivativeTradingTypeLimit,
      tradingTypeMarket: derivativeTradingTypeMarket
    } = useDerivativeFormFormatter(formValues);
    const {
      tradingTypeLimit: spotTradingTypeLimit,
      tradingTypeMarket: spotTradingTypeMarket
    } = useSpotFormFormatter(formValues);
    const tradingTypeLimit = props.isSpot ? spotTradingTypeLimit : derivativeTradingTypeLimit;
    const tradingTypeMarket = props.isSpot ? spotTradingTypeMarket : derivativeTradingTypeMarket;
    const { value: reduceOnly, setValue: setReduceOnlyValue } = useField(
      TradeField.ReduceOnly
    );
    const { value: postOnly, setValue: setPostOnlyValue } = useField(
      TradeField.PostOnly
    );
    const {
      value: slippageTolerance,
      errors: slippageToleranceError,
      setValue: setSlippageToleranceValue
    } = useStringField({
      name: TradeField.SlippageTolerance,
      initialValue: "0.5",
      rule: "slippage"
    });
    const postOnlyValue = computed({
      get: () => postOnly.value,
      set: (postOnly2) => {
        setPostOnlyValue(postOnly2);
      }
    });
    const reduceOnlyValue = computed({
      get: () => reduceOnly.value,
      set: (reduceOnly2) => {
        const LEVERAGE_FOR_REDUCE_ONLY = "1";
        setReduceOnlyValue(reduceOnly2);
        formValues.value[TradeField.Leverage] = LEVERAGE_FOR_REDUCE_ONLY;
      }
    });
    const wrapperClasses = computed(() => {
      const classes = [
        "rounded",
        "border",
        "shadow-none",
        "border-transparent",
        "input-wrapper"
      ];
      if (showSlippageWarning.value) {
        classes.push("border-orange-500 bg-orange-500 bg-opacity-10");
      }
      if (showSlippageError.value) {
        classes.push("border-red-500 bg-red-500 bg-opacity-10");
      }
      return classes.join(" ");
    });
    const inputClasses = computed(() => {
      const classes = ["input-small", "text-right", "text-sm", "px-1"];
      if (showSlippageWarning.value || showSlippageError.value) {
        classes.push("input-small bg-transparent text-right text-sm px-1");
      }
      return classes.join(" ");
    });
    const showSlippageAsSelectableOrDefaultForMarket = computed(
      () => [
        "Selectable" /* Selectable */,
        "Zero" /* NonSelectableDefault */
      ].includes(slippageSelection.value)
    );
    const showSlippageInputFieldForMarket = computed(
      () => slippageSelection.value === "Selected" /* SlippageInput */ && (tradingTypeMarket.value || tradingTypeStopMarket.value)
    );
    const reduceOnlyTooltip = computed(() => {
      if (!props.reduceOnlyDisabled) {
        return;
      }
      return isConditionalOrder.value ? t("trade.reduceOnlyTooltipConditional") : t("trade.reduceOnlyTooltip");
    });
    const showSlippageWarning = computed(
      () => (tradingTypeMarket.value || tradingTypeStopMarket.value) && slippageToleranceError.value.some(
        (error) => error === tradeErrorMessages.slippageTooLow() || error === tradeErrorMessages.slippageTooHigh()
      )
    );
    const showSlippageError = computed(
      () => (tradingTypeMarket.value || tradingTypeStopMarket.value) && slippageToleranceError.value.includes(tradeErrorMessages.slippageExceed())
    );
    watch(slippageTolerance, (tolerance) => {
      if (new BigNumberInBase(tolerance).gt(0)) {
        slippageIsToggleable.value = true;
      }
    });
    function handleBlur(value) {
      if (value === "") {
        value = DEFAULT_SLIPPAGE.toFormat(1);
      }
      if (Number(value) > MAX_SLIPPAGE.toNumber()) {
        value = MAX_SLIPPAGE.toFixed();
      }
      setSlippageToleranceValue(value);
      toggleSlippageToSelectable();
    }
    function handleSlippageCheckboxToggle() {
      if (slippageIsToggleable.value) {
        setToZeroSlippage();
      } else {
        setToDefaultSlippage();
        toggleSlippageToSelectable();
      }
    }
    function setToZeroSlippage() {
      slippageSelection.value = "Zero" /* NonSelectableDefault */;
      setSlippageToleranceValue("0");
    }
    function setToDefaultSlippage() {
      slippageSelection.value = "Zero" /* NonSelectableDefault */;
      setSlippageToleranceValue(DEFAULT_SLIPPAGE.toFormat(1));
    }
    function toggleSlippageToSelectable() {
      slippageSelection.value = "Selectable" /* Selectable */;
    }
    function toggleToSlippageInput() {
      slippageSelection.value = "Selected" /* SlippageInput */;
      window.requestAnimationFrame(focusOnSlippageInput);
    }
    function focusOnSlippageInput() {
      const slippageInput = document.getElementById(
        "focusOnInput"
      );
      if (slippageInput) {
        slippageInput.focus();
      }
    }
    function toggleDrawer() {
      drawerIsOpen.value = !drawerIsOpen.value;
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppCheckbox = __nuxt_component_1;
      const _component_AppInputNumeric = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "group flex align-center my-2 cursor-pointer select-none",
          onClick: _withModifiers(toggleDrawer, ["stop"])
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.advanced_settings")), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseIcon, {
              name: "caret-down",
              class: _normalizeClass(["text-gray-500 group-hover:text-gray-200 transform rotate-180 self-center w-4 h-4", { "rotate-0": !_unref(drawerIsOpen) }])
            }, null, 8, ["class"])
          ])
        ], 8, _hoisted_2),
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            !__props.isSpot ? (_openBlock(), _createBlock(_component_AppCheckbox, {
              key: 0,
              modelValue: _unref(reduceOnlyValue),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(reduceOnlyValue) ? reduceOnlyValue.value = $event : null),
              disabled: __props.reduceOnlyDisabled,
              tooltip: _unref(reduceOnlyTooltip),
              "data-cy": "trading-page-reduce-only-checkbox"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("trade.reduce_only")), 1)
                ])
              ]),
              _: 3
            }, 8, ["modelValue", "disabled", "tooltip"])) : _createCommentVNode("", true),
            _unref(tradingTypeMarket) || _unref(tradingTypeStopMarket) ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_AppCheckbox, {
                modelValue: _unref(slippageIsToggleable),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(slippageIsToggleable) ? slippageIsToggleable.value = $event : null),
                "data-cy": "trading-page-slippage-checkbox",
                onInput: handleSlippageCheckboxToggle
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, () => [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(`${_ctx.$t("trade.slippage_tolerance")} :`), 1)
                  ])
                ]),
                _: 3
              }, 8, ["modelValue"]),
              _withDirectives(_createElementVNode("div", {
                class: "group flex items-center cursor-pointer gap-2",
                onClick: _cache[2] || (_cache[2] = ($event) => toggleToSlippageInput())
              }, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(slippageTolerance)) + "%", 1),
                _createVNode(_component_BaseIcon, {
                  name: "caret-down",
                  class: _normalizeClass(["text-gray-500 group-hover:text-gray-200 w-4 h-4", {
                    invisible: !_unref(slippageIsToggleable)
                  }]),
                  "data-cy": "trading-page-slippage-toggle-icon"
                }, null, 8, ["class"])
              ], 512), [
                [_vShow, _unref(showSlippageAsSelectableOrDefaultForMarket)]
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_11, [
                _createVNode(_component_AppInputNumeric, {
                  id: "focusOnInput",
                  modelValue: _unref(slippageTolerance),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => _isRef(slippageTolerance) ? slippageTolerance.value = $event : null),
                  "transparent-bg": "",
                  "wrapper-classes": _unref(wrapperClasses),
                  "input-classes": _unref(inputClasses),
                  disabled: !_unref(slippageIsToggleable),
                  step: 0.01,
                  "max-decimals": 2,
                  small: "",
                  "show-prefix": _unref(showSlippageWarning) || _unref(showSlippageError),
                  "data-cy": "trading-page-slippage-input",
                  onBlur: handleBlur
                }, {
                  addon: _withCtx(() => [
                    _hoisted_12
                  ]),
                  _: 1
                }, 8, ["modelValue", "wrapper-classes", "input-classes", "disabled", "step", "show-prefix"])
              ], 512), [
                [_vShow, _unref(showSlippageInputFieldForMarket)]
              ])
            ])) : _createCommentVNode("", true),
            _unref(tradingTypeLimit) ? (_openBlock(), _createBlock(_component_AppCheckbox, {
              key: 2,
              modelValue: _unref(postOnlyValue),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => _isRef(postOnlyValue) ? postOnlyValue.value = $event : null),
              "data-cy": "trading-page-post-only-checkbox"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("trade.post_only")), 1)
                ])
              ]),
              _: 3
            }, 8, ["modelValue"])) : _createCommentVNode("", true)
          ])
        ], 512), [
          [_vShow, _unref(drawerIsOpen)]
        ]),
        _unref(showSlippageWarning) || _unref(showSlippageError) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex mt-4 gap-2 text-xs font-semibold", _unref(showSlippageWarning) ? "text-orange-500" : "text-red-500"]),
          "font-semibold": "",
          "text-2xs": ""
        }, [
          _createVNode(_component_BaseIcon, {
            name: "exclamation-circle-fill",
            sm: "",
            class: "mt-0.25"
          }),
          _createElementVNode("span", null, _toDisplayString(_unref(slippageToleranceError)[0]), 1)
        ], 2)) : _createCommentVNode("", true)
      ]);
    };
  }
});
