import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Market/Stats/Partials/LastTradedPriceAndChange.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "flex flex-col" };
const _hoisted_3 = {
  class: "font-semibold text-gray-200",
  "data-cy": "markets-ticker-table-data"
};
const _hoisted_4 = {
  class: "text-gray-500 tracking-wide mt-1 font-mono",
  "data-cy": "markets-volume_24h-table-data"
};
import { getMarketRoute } from "@/app/utils/market";
import { stableCoinDenoms } from "@/app/data/token";
import {
  UI_DEFAULT_PRICE_DISPLAY_DECIMALS,
  UI_MINIMAL_ABBREVIATION_FLOOR
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketsTableRow",
  props: {
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    volumeInUsd: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const appStore = useAppStore();
    const marketRoute = getMarketRoute(props.market) || { name: "markets" };
    const isFavorite = computed(() => {
      return appStore.favoriteMarkets.includes(props.market.marketId);
    });
    const formatterOptions = computed(() => {
      return stableCoinDenoms.includes(props.market.quoteToken.symbol) ? {
        decimalPlaces: 0,
        abbreviationFloor: UI_MINIMAL_ABBREVIATION_FLOOR
      } : {
        abbreviationFloor: void 0,
        decimalPlaces: UI_DEFAULT_PRICE_DISPLAY_DECIMALS
      };
    });
    const { valueToString: abbreviatedVolumeInUsdToFormat } = useBigNumberFormatter(
      computed(() => props.volumeInUsd),
      formatterOptions.value
    );
    function updateWatchList() {
      appStore.updateFavoriteMarkets(props.market.marketId);
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_PartialsTradingMarketStatsPartialsLastTradedPriceAndChange = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", {
        class: "flex justify-start text-gray-200 gap-4 text-xs px-3 py-2 bg-gray-900 items-center hover:bg-gray-850",
        "data-cy": `markets-table-row-${__props.market.ticker}`
      }, [
        _createElementVNode("div", {
          class: "text-gray-500 w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-400 hover:text-gray-400 hover:bg-opacity-10 cursor-pointer",
          "data-cy": "markets-favorite-button",
          onClick: updateWatchList
        }, [
          _unref(isFavorite) ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            name: "star",
            class: "min-w-5 w-5 h-5",
            "data-cy": "markets-is-favorite-icon"
          })) : (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            name: "star-border",
            class: "min-w-5 w-5 h-5"
          }))
        ]),
        _createVNode(_component_NuxtLink, {
          class: "cursor-pointer flex items-center justify-between w-full",
          to: _unref(marketRoute),
          "data-cy": "markets-trade-link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(__props.market.ticker), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(abbreviatedVolumeInUsdToFormat)) + " USD ", 1)
            ]),
            _createVNode(_component_PartialsTradingMarketStatsPartialsLastTradedPriceAndChange, _normalizeProps(_guardReactiveProps({
              ..._ctx.$attrs,
              market: __props.market,
              summary: __props.summary
            })), null, 16)
          ]),
          _: 1
        }, 8, ["to"])
      ], 8, _hoisted_1);
    };
  }
});
