"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "text-xs text-gray-400 flex items-center font-mono" };
const _hoisted_2 = ["data-cy", "onClick"];
import { BigNumberInBase } from "@injectivelabs/utils";
import { formatAmountToAllowableAmount } from "@injectivelabs/sdk-ts";
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import {
  TradeField
} from "@/types";
import {
  TRADE_FORM_QUANTITY_ROUNDING_MODE,
  TRADE_FORM_PRICE_ROUNDING_MODE
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "PercentageOptions",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    orderTypeReduceOnly: Boolean,
    baseAvailableBalance: {
      type: Object,
      default: void 0
    },
    feeRate: {
      type: Object,
      default: ZERO_IN_BASE
    },
    market: {
      type: Object,
      required: true
    },
    maxAmountOnOrderbook: {
      type: Object,
      required: true
    },
    maxReduceOnly: {
      type: Object,
      default: void 0
    },
    orderbookOrders: {
      type: Array,
      required: true
    },
    position: {
      type: Object,
      default: void 0
    },
    quoteAvailableBalance: {
      type: Object,
      required: true
    }
  },
  emits: ["update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const formValues = useFormValues();
    const percentages = [25, 50, 75, 100];
    const { value: percentage, setValue: setProportionalPercentageValue } = useNumberField({
      name: TradeField.ProportionalPercentage,
      initialValue: 0,
      rule: ""
    });
    const spotAvailableBalanceGreaterThanOrderbook = computed(() => {
      var _a;
      const { totalNotional, totalQuantity } = props.maxAmountOnOrderbook;
      const percentageToNumber = new BigNumberInBase(percentage.value).div(100);
      return props.isBuy ? props.quoteAvailableBalance.times(percentageToNumber).gt(totalNotional) : (_a = props.baseAvailableBalance) == null ? void 0 : _a.times(percentageToNumber).gt(totalQuantity);
    });
    const derivativeAvailableBalanceGreaterThanOrderbook = computed(() => {
      const { totalNotional } = props.maxAmountOnOrderbook;
      const percentageToNumber = new BigNumberInBase(percentage.value).div(100);
      return props.quoteAvailableBalance.times(percentageToNumber).gt(totalNotional);
    });
    const feeRate = computed(
      () => props.feeRate.lt(0) ? ZERO_IN_BASE : props.feeRate
    );
    const balanceToUpdateSpotWithFees = computed(() => {
      const percentageFormatted = new BigNumberInBase(percentage.value).div(100);
      const availableBalance = props.isBuy ? props.quoteAvailableBalance : props.baseAvailableBalance;
      const balanceToUpdateSpot = new BigNumberInBase(
        availableBalance || ZERO_IN_BASE
      ).times(percentageFormatted);
      return props.isBuy ? balanceToUpdateSpot.minus(balanceToUpdateSpot.times(feeRate.value)) : balanceToUpdateSpot;
    });
    const balanceToUpdateDerivativesWithFees = computed(() => {
      const percentageFormatted = new BigNumberInBase(percentage.value).div(100);
      const balanceToUpdateDerivative = new BigNumberInBase(
        props.quoteAvailableBalance || ZERO_IN_BASE
      ).times(percentageFormatted).times(formValues.value[TradeField.Leverage]);
      return balanceToUpdateDerivative.minus(
        balanceToUpdateDerivative.times(feeRate.value)
      );
    });
    watch(
      () => props.feeRate,
      () => {
        if (!percentage.value) {
          return;
        }
        handlePercentageChange(percentage.value);
      }
    );
    function handleReduceOnly() {
      if (!props.maxReduceOnly) {
        return;
      }
      const { totalQuantity } = props.maxAmountOnOrderbook;
      const maxReduceOnlyFromPercentage = props.maxReduceOnly.times(percentage.value).dividedBy(100);
      const amount = BigNumberInBase.minimum(
        maxReduceOnlyFromPercentage,
        totalQuantity
      );
      formValues.value[TradeField.BaseAmount] = amount.toFixed(
        props.market.quantityDecimals,
        TRADE_FORM_QUANTITY_ROUNDING_MODE
      );
      emit("update:amount", { isBaseAmount: true });
    }
    function handleDerivativePercentageChange() {
      const field = derivativeAvailableBalanceGreaterThanOrderbook.value ? TradeField.BaseAmount : TradeField.QuoteAmount;
      const amount = derivativeAvailableBalanceGreaterThanOrderbook.value ? props.maxAmountOnOrderbook.totalQuantity : balanceToUpdateDerivativesWithFees.value;
      const decimals = derivativeAvailableBalanceGreaterThanOrderbook.value ? props.market.quantityDecimals : props.market.priceDecimals;
      const roundingMode = derivativeAvailableBalanceGreaterThanOrderbook.value ? TRADE_FORM_QUANTITY_ROUNDING_MODE : TRADE_FORM_PRICE_ROUNDING_MODE;
      formValues.value[field] = amount.toFixed(decimals, roundingMode);
      emit("update:amount", {
        isBaseAmount: derivativeAvailableBalanceGreaterThanOrderbook.value
      });
    }
    function handleSpotPercentageChange() {
      const field = spotAvailableBalanceGreaterThanOrderbook.value || !props.isBuy ? TradeField.BaseAmount : TradeField.QuoteAmount;
      const amount = spotAvailableBalanceGreaterThanOrderbook.value ? props.maxAmountOnOrderbook.totalQuantity : balanceToUpdateSpotWithFees.value;
      const decimals = spotAvailableBalanceGreaterThanOrderbook.value || !props.isBuy ? props.market.quantityDecimals : props.market.priceDecimals;
      const roundingMode = spotAvailableBalanceGreaterThanOrderbook.value || !props.isBuy ? TRADE_FORM_QUANTITY_ROUNDING_MODE : TRADE_FORM_PRICE_ROUNDING_MODE;
      formValues.value[field] = amount.toFixed(decimals, roundingMode);
      emit("update:amount", {
        isBaseAmount: derivativeAvailableBalanceGreaterThanOrderbook.value || !props.isBuy
      });
    }
    function handlePercentageChange(percentage2) {
      setProportionalPercentageValue(percentage2);
      if (props.orderTypeReduceOnly) {
        return handleReduceOnly();
      }
      if (!props.isSpot) {
        return handleDerivativePercentageChange();
      }
      return handleSpotPercentageChange();
    }
    watch(
      () => formValues.value[TradeField.BaseAmount],
      () => {
        if (props.market.quantityTensMultiplier < 1 || !percentage.value) {
          return;
        }
        formValues.value[TradeField.BaseAmount] = formatAmountToAllowableAmount(
          formValues.value[TradeField.BaseAmount],
          props.market.quantityTensMultiplier
        );
        emit("update:amount", {
          isBaseAmount: true
        });
      }
    );
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(percentages, (percent, index) => {
          return _createElementVNode("span", {
            key: `percentage-${index}`,
            "data-cy": `trading-page-percentage-selector-${percent}-span`,
            class: "mr-1 cursor-pointer",
            onClick: _withModifiers(($event) => handlePercentageChange(percent), ["stop"])
          }, _toDisplayString(percent) + "% ", 9, _hoisted_2);
        }), 64))
      ]);
    };
  }
});
