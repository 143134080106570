import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = ["textContent"];
const _hoisted_3 = { class: "mt-6 flex items-center justify-center" };
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketBeta",
  setup(__props) {
    const modalStore = useModalStore();
    const isModalOpen = computed(() => modalStore.modals[Modal.MarketBeta]);
    function closeModal() {
      modalStore.closeModal(Modal.MarketBeta);
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      const _component_AppModal = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "hide-close-button": ""
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("marketBeta.title")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", {
              class: "text-center text-sm text-gray-100",
              textContent: _toDisplayString(_ctx.$t("marketBeta.description"))
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppButton, {
                class: "bg-blue-500 text-blue-900",
                onClick: closeModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("marketBeta.I Understand")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
