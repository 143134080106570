import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/TextInfo.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue";
const _hoisted_1 = { class: "flex items-center gap-2 ml-2" };
const _hoisted_2 = {
  key: 0,
  class: "font-mono flex items-start break-all",
  "data-cy": "trading-page-details-fee-value-text-content"
};
const _hoisted_3 = /* @__PURE__ */ _createElementVNode("span", { class: "mr-1" }, "\u2248", -1);
const _hoisted_4 = { class: "text-gray-500 ml-1 break-normal" };
const _hoisted_5 = {
  key: 1,
  class: "text-gray-500 ml-1"
};
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { TradeExecutionType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "FeeRate",
  props: {
    postOnly: Boolean,
    fees: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    notionalValue: {
      type: Object,
      default: ZERO_IN_BASE
    },
    tradingType: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    const props = __props;
    const {
      makerFeeRate,
      makerFeeRateDiscount,
      takerFeeRate,
      takerFeeRateDiscount
    } = useTradeFee(computed(() => props.market));
    const marketHasNegativeMakerFee = computed(
      () => new BigNumberInBase(props.market.makerFeeRate).lt(0)
    );
    const tradeTypeMarket = computed(
      () => [TradeExecutionType.Market, TradeExecutionType.StopMarket].includes(
        props.tradingType
      )
    );
    const feeReturned = computed(() => {
      if (!props.notionalValue) {
        return ZERO_IN_BASE;
      }
      if (props.notionalValue.isNaN() || props.notionalValue.lte(0)) {
        return ZERO_IN_BASE;
      }
      return props.notionalValue.times(
        new BigNumberInBase(takerFeeRate.value).minus(makerFeeRate.value.abs())
      );
    });
    const { valueToString: feesToFormat } = useBigNumberFormatter(
      computed(() => props.fees),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const { valueToString: feeReturnedToFormat } = useBigNumberFormatter(
      feeReturned,
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      const _component_CommonTextInfo = __nuxt_component_2;
      return !(__props.postOnly && _unref(marketHasNegativeMakerFee)) || _unref(tradeTypeMarket) ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
        key: 0,
        title: _ctx.$t("trade.fee"),
        class: "mt-2"
      }, _createSlots({
        default: _withCtx(() => [
          __props.fees.gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _hoisted_3,
            _createTextVNode(" " + _toDisplayString(_unref(feesToFormat)) + " ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(__props.market.quoteToken.symbol), 1)
          ])) : (_openBlock(), _createElementBlock("span", _hoisted_5, " \u2014 "))
        ]),
        _: 2
      }, [
        !_unref(tradeTypeMarket) ? {
          name: "context",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_CommonInfoTooltip, {
                class: "ml-2",
                tooltip: _unref(marketHasNegativeMakerFee) ? _ctx.$t("trade.fee_order_details_note_negative_margin") : _ctx.$t("trade.fee_order_details_note", {
                  feeReturnedToFormat: _unref(feeReturnedToFormat)
                })
              }, null, 8, ["tooltip"]),
              _unref(makerFeeRateDiscount).gt(0) || _unref(takerFeeRateDiscount).gt(0) ? (_openBlock(), _createBlock(_component_CommonInfoTooltip, {
                key: 0,
                class: "text-blue-500",
                tooltip: _ctx.$t("trade.fees_tooltip_discount", {
                  maker: _unref(makerFeeRateDiscount).times(100).toFixed(),
                  taker: _unref(takerFeeRateDiscount).times(100).toFixed()
                })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "check-circle",
                    class: "text-blue-500 w-3 h-3 min-w-3"
                  })
                ]),
                _: 1
              }, 8, ["tooltip"])) : _createCommentVNode("", true)
            ])
          ]),
          key: "0"
        } : {
          name: "context",
          fn: _withCtx(() => [
            _createElementVNode("div", { class: "flex items-center gap-2 ml-2" }, [
              _createVNode(_component_CommonInfoTooltip, {
                tooltip: _ctx.$t("trade.fees_tooltip")
              }, null, 8, ["tooltip"]),
              _unref(takerFeeRateDiscount).gt(0) ? (_openBlock(), _createBlock(_component_CommonInfoTooltip, {
                key: 0,
                class: "text-blue-500",
                tooltip: _ctx.$t("trade.taker_fees_tooltip_discount", {
                  taker: _unref(takerFeeRateDiscount).times(100).toFixed()
                })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "check-circle",
                    class: "text-blue-500 w-3 h-3 min-w-3"
                  })
                ]),
                _: 1
              }, 8, ["tooltip"])) : _createCommentVNode("", true)
            ])
          ]),
          key: "1"
        }
      ]), 1032, ["title"])) : _createCommentVNode("", true);
    };
  }
});
