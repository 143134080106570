import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Market/Trades/Header.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Market/Trades/Item.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col flex-wrap" };
const _hoisted_2 = { class: "flex-1 w-full overflow-y-auto overflow-x-hidden rounded-b-lg" };
const _hoisted_3 = { class: "list-trades w-full" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const isSpot = props.market.type === MarketType.Spot;
    const trades = computed(
      () => isSpot ? spotStore.trades : derivativeStore.trades
    );
    return (_ctx, _cache) => {
      const _component_PartialsTradingMarketTradesHeader = __nuxt_component_0;
      const _component_PartialsTradingMarketTradesItem = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PartialsTradingMarketTradesHeader, { market: __props.market }, null, 8, ["market"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(trades), (trade, index) => {
              return _openBlock(), _createBlock(_component_PartialsTradingMarketTradesItem, {
                key: `trade-${index}`,
                market: __props.market,
                trade
              }, null, 8, ["market", "trade"]);
            }), 128))
          ])
        ])
      ]);
    };
  }
});
