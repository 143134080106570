import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Numeric.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "pt-1" };
const _hoisted_2 = { class: "text-xs text-gray-400" };
const _hoisted_3 = { class: "range-wrap flex items-center relative select-none gap-2" };
const _hoisted_4 = ["max"];
const _hoisted_5 = { class: "relative max-h-6" };
const _hoisted_6 = /* @__PURE__ */ _createElementVNode("span", { class: "absolute top-0 right-0 text-xs text-gray-400 mt-1.5 mr-1.5" }, " x ", -1);
import { BigNumberInBase } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "OrderLeverage",
  props: {
    isBuy: Boolean,
    executionPrice: {
      type: Object,
      required: true
    },
    leverageFieldName: {
      type: String,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    worstPriceWithSlippage: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const formValues = useFormValues();
    const { tradingTypeMarket } = useSpotFormFormatter(formValues);
    const { markPrice } = useDerivativeLastPrice(computed(() => props.market));
    const maxLeverageAvailable = computed(() => {
      const maxLeverage = new BigNumberInBase(
        new BigNumberInBase(1).dividedBy(
          props.market.initialMarginRatio
        ).dp(0)
      );
      const steps = [1, 2, 5, 10, 20, 50, 100, 150, 200];
      const stepsLessThanMaxLeverage = steps.filter(
        (step) => step <= maxLeverage.toNumber()
      );
      return stepsLessThanMaxLeverage.length > 0 ? new BigNumberInBase(
        stepsLessThanMaxLeverage[stepsLessThanMaxLeverage.length - 1]
      ).toFixed() : new BigNumberInBase(20).toFixed();
    });
    const maxLeverageAllowed = computed(() => {
      const useExecutionPrice = !tradingTypeMarket.value;
      const price = useExecutionPrice ? props.executionPrice : props.worstPriceWithSlippage;
      const priceWithMarginRatio = new BigNumberInBase(markPrice.value).times(
        props.market.initialMarginRatio
      );
      const priceBasedOnOrderType = props.isBuy ? priceWithMarginRatio.minus(markPrice.value).plus(price) : priceWithMarginRatio.plus(markPrice.value).minus(price);
      return price.dividedBy(priceBasedOnOrderType);
    });
    const {
      value: leverage,
      setValue,
      resetField
    } = useStringField({
      name: props.leverageFieldName,
      initialValue: "1",
      rule: "required",
      dynamicRule: computed(
        () => `maxLeverage:${maxLeverageAllowed.value.toFixed()},${props.isBuy}`
      )
    });
    function validateLeverage(value) {
      nextTick(() => {
        const leverageToBigNumber = new BigNumberInBase(value);
        if (leverageToBigNumber.gte(maxLeverageAvailable.value)) {
          setValue(maxLeverageAvailable.value);
        } else if (leverageToBigNumber.lt(1)) {
          resetField();
        }
      });
    }
    return (_ctx, _cache) => {
      const _component_AppInputNumeric = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("trade.max_leverage", { max: _unref(maxLeverageAvailable) })), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(leverage) ? leverage.value = $event : null),
            min: "1",
            max: _unref(maxLeverageAvailable).toString(),
            step: "0.01",
            class: "range",
            type: "range",
            "on:update:modelValue": validateLeverage
          }, null, 40, _hoisted_4), [
            [_vModelText, _unref(leverage)]
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppInputNumeric, {
              modelValue: _unref(leverage),
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event) => _isRef(leverage) ? leverage.value = $event : null),
                validateLeverage
              ],
              min: "0",
              step: "0.01",
              max: _unref(maxLeverageAvailable).toString(),
              class: "leverage-input pr-4 h-1",
              "data-cy": "trading-page-leverage-input"
            }, null, 8, ["modelValue", "max"]),
            _hoisted_6
          ])
        ])
      ]);
    };
  }
});
