"use strict";
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import {
  Change,
  ZERO_IN_BASE,
  ZERO_TO_STRING
} from "@injectivelabs/sdk-ui-ts";
export function useDerivativeLastPrice(market) {
  const derivateStore = useDerivativeStore();
  const latestTrade = computed(() => {
    if (derivateStore.trades.length === 0) {
      return void 0;
    }
    return derivateStore.trades[0];
  });
  const lastTradedPrice = computed(() => {
    if (!market.value || !latestTrade.value) {
      return ZERO_IN_BASE;
    }
    return new BigNumberInBase(
      new BigNumberInWei(latestTrade.value.executionPrice).toBase(
        market.value.quoteToken.decimals
      )
    );
  });
  const changeInPercentage = computed(() => {
    if (!latestTrade.value) {
      return 0;
    }
    const [secondLastTrade] = derivateStore.trades.filter(
      (trade) => !new BigNumberInBase(trade.executionPrice).eq(
        latestTrade.value.executionPrice
      )
    );
    if (!secondLastTrade) {
      return 0;
    }
    const lastPrice = new BigNumberInBase(latestTrade.value.executionPrice);
    const secondLastPrice = new BigNumberInBase(secondLastTrade.executionPrice);
    return lastPrice.minus(secondLastPrice).dividedBy(secondLastPrice).times(100).toFixed();
  });
  const lastTradedPriceChange = computed(() => {
    const changeInPercentageInBigNumber = new BigNumberInBase(
      changeInPercentage.value
    );
    if (changeInPercentageInBigNumber.eq(0)) {
      return Change.NoChange;
    }
    return changeInPercentageInBigNumber.gt(0) ? Change.Increase : Change.Decrease;
  });
  const marketMarkPrice = computed(() => {
    var _a;
    if (!market.value) {
      return ZERO_TO_STRING;
    }
    return (_a = derivateStore.marketMarkPriceMap[market.value.marketId]) == null ? void 0 : _a.price;
  });
  const markPrice = computed(() => {
    return marketMarkPrice.value || lastTradedPrice.value.toFixed();
  });
  return {
    markPrice,
    lastTradedPrice,
    changeInPercentage,
    lastTradedPriceChange
  };
}
