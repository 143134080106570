import { default as __nuxt_component_0 } from "/opt/build/repo/components/Modals/PeggyUsdcDetected.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "text-blue-500 font-semibold" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { Modal } from "@/types";
import { usdcTokenDenom } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "PeggyUsdcConvert",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const modalStore = useModalStore();
    const { accountBalancesWithToken } = useBalance();
    const hasUsdcPeggyBalance = computed(() => {
      if (usdcTokenDenom.USDCet.toLowerCase() !== props.market.quoteDenom.toLowerCase()) {
        return false;
      }
      const peggyUsdcBalance = accountBalancesWithToken.value.find(
        (accountBalance) => {
          return accountBalance.denom.toLowerCase() === usdcTokenDenom.USDC.toLowerCase();
        }
      );
      return new BigNumberInBase((peggyUsdcBalance == null ? void 0 : peggyUsdcBalance.availableMargin) || 0).gt(0);
    });
    onMounted(() => {
      nextTick(() => {
        if (hasUsdcPeggyBalance.value) {
          openModal();
        }
      });
    });
    function openModal() {
      modalStore.openModal({ type: Modal.USDCDetected });
    }
    return (_ctx, _cache) => {
      const _component_ModalsPeggyUsdcDetected = __nuxt_component_0;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _unref(hasUsdcPeggyBalance) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "cursor-pointer",
          onClick: openModal
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("trade.convert.convert")), 1)
        ])) : _createCommentVNode("", true),
        _createVNode(_component_ModalsPeggyUsdcDetected, { market: __props.market }, null, 8, ["market"])
      ], 64);
    };
  }
});
