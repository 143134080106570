import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-2xs text-red-200 mb-4"
};
const _hoisted_2 = {
  key: 1,
  class: "text-2xs text-red-400 mb-4"
};
const _hoisted_3 = { class: "mr-1" };
export default /* @__PURE__ */ _defineComponent({
  __name: "OrderError",
  props: {
    highDeviation: Boolean
  },
  setup(__props) {
    const bankStore = useBankStore();
    const walletStore = useWalletStore();
    const faucetUrl = "https://inj.supply/";
    const notEnoughInjForGas = computed(
      () => walletStore.isUserWalletConnected && !bankStore.hasEnoughInjForGas
    );
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", null, [
          __props.highDeviation ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t("trade.execution_price_far_away_from_last_traded_price")), 1)) : _createCommentVNode("", true),
          _unref(notEnoughInjForGas) ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.$t("insufficientGas.tradingFormNote")) + " ", 1),
            _createElementVNode("a", {
              href: faucetUrl,
              target: "_blank",
              class: "flex items-center text-blue-500"
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("insufficientGas.getFreeInj")), 1),
              _createVNode(_component_BaseIcon, {
                name: "external-link",
                class: "w-2 h-2"
              })
            ])
          ])) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});
