import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/OrderDetails/MakerTakerFeeRate.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/OrderDetails/FeeRate.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/OrderDetails/FeeRebate.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/OrderDetails/ExpectedPoints.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Loading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Suspense as _Suspense, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full h-40" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { ZERO_IN_BASE, MarketType } from "@injectivelabs/sdk-ui-ts";
import { TRADE_FORM_PRICE_ROUNDING_MODE } from "@/app/utils/constants";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    isBuy: Boolean,
    orderTypeReduceOnly: Boolean,
    executionPrice: {
      type: Object,
      required: true
    },
    fees: {
      type: Object,
      required: true
    },
    feeRate: {
      type: Object,
      required: true
    },
    liquidationPrice: {
      type: Object,
      default: void 0
    },
    market: {
      type: Object,
      required: true
    },
    notionalValue: {
      type: Object,
      default: void 0
    },
    notionalWithFees: {
      type: Object,
      required: true
    },
    slippage: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isProduction = process.env.NODE_ENV === "production";
    const isWebpack = process.env.BUILDER_TYPE === "webpack" || isProduction;
    const formValues = useFormValues();
    const isSpot = props.market.type === MarketType.Spot;
    const {
      baseAmount: derivativeBaseAmount,
      quoteAmount: derivativeQuoteAmount,
      tradingTypeLimit: derivativeTradingTypeLimit,
      tradingTypeMarket: derivativeTradingTypeMarket
    } = useDerivativeFormFormatter(formValues);
    const {
      baseAmount: spotBaseAmount,
      quoteAmount: spotQuoteAmount,
      tradingTypeLimit: spotTradingTypeLimit,
      tradingTypeMarket: spotTradingTypeMarket
    } = useSpotFormFormatter(formValues);
    const tradingTypeLimit = isSpot ? spotTradingTypeLimit : derivativeTradingTypeLimit;
    const tradingTypeMarket = isSpot ? spotTradingTypeMarket : derivativeTradingTypeMarket;
    const orderDetailsComponentPath = computed(() => {
      const componentName = tradingTypeMarket.value || tradingTypeLimit.value || isSpot ? "OrderDetails" : "ConditionalOrderDetails";
      const spotPath = `Spot/Trading/${componentName}`;
      const derivativePath = `Derivatives/Trading/${componentName}`;
      return isSpot ? spotPath : derivativePath;
    });
    const orderDetailsComponent = defineAsyncComponent(() => {
      return new Promise((resolve, _reject) => {
        if (!isWebpack) {
          const comps = import.meta.glob(
            /* @vite-ignore */
            "./../**/*.vue"
          );
          return comps[
            /* @vite-ignore */
            `../${orderDetailsComponentPath.value}.vue`
          ]().then((component) => resolve(component.default));
        }
        import(
          /* @vite-ignore */
          `../${orderDetailsComponentPath.value}.vue`
        ).then(
          (component) => resolve(component)
        );
      });
    });
    const { valueToString: executionPriceToFormat } = useBigNumberFormatter(
      computed(() => props.executionPrice),
      {
        decimalPlaces: props.market.priceDecimals,
        roundingMode: TRADE_FORM_PRICE_ROUNDING_MODE
      }
    );
    const { valueToString: notionalWithFeesToFormat } = useBigNumberFormatter(
      computed(() => props.notionalWithFees),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const minimumReceivedAmount = computed(() => {
      if (props.executionPrice.lte("0")) {
        return ZERO_IN_BASE;
      }
      const baseAmount = isSpot ? spotBaseAmount : derivativeBaseAmount;
      const quoteAmount = isSpot ? spotQuoteAmount : derivativeQuoteAmount;
      const feeMultiplier = new BigNumberInBase(1).minus(props.feeRate);
      const minimumReceivedBaseAmount = isSpot ? baseAmount.value.times(props.executionPrice).times(feeMultiplier) : baseAmount.value.times(props.executionPrice);
      const minimumReceivedQuoteAmount = quoteAmount.value.div(props.executionPrice);
      return props.isBuy ? minimumReceivedQuoteAmount : minimumReceivedBaseAmount;
    });
    const { valueToString: minimumReceivedAmountToFormat } = useBigNumberFormatter(
      computed(() => minimumReceivedAmount.value || 0),
      {
        decimalPlaces: props.isBuy ? props.market.quantityDecimals : props.market.priceDecimals
      }
    );
    return (_ctx, _cache) => {
      const _component_PartialsTradingOrderDetailsMakerTakerFeeRate = __nuxt_component_0;
      const _component_PartialsTradingOrderDetailsFeeRate = __nuxt_component_1;
      const _component_PartialsTradingOrderDetailsFeeRebate = __nuxt_component_2;
      const _component_PartialsTradingOrderDetailsExpectedPoints = __nuxt_component_3;
      const _component_AppLoading = __nuxt_component_4;
      return _openBlock(), _createElementBlock("div", {
        key: _unref(formValues)[_unref(TradeField).TradingType]
      }, [
        (_openBlock(), _createBlock(_Suspense, null, {
          fallback: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AppLoading)
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(orderDetailsComponent)), _normalizeProps(_guardReactiveProps({
              executionPrice: __props.executionPrice,
              isBuy: __props.isBuy,
              liquidationPrice: __props.liquidationPrice,
              market: __props.market,
              minimumReceivedAmount: _unref(minimumReceivedAmount),
              notionalValue: __props.notionalValue,
              orderTypeReduceOnly: __props.orderTypeReduceOnly
            })), {
              total: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(notionalWithFeesToFormat)), 1)
              ]),
              executionPrice: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(executionPriceToFormat)), 1)
              ]),
              marketMinimumReceivedAmount: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(minimumReceivedAmountToFormat)), 1)
              ]),
              makerTakerFeeRate: _withCtx(() => [
                _createVNode(_component_PartialsTradingOrderDetailsMakerTakerFeeRate, _normalizeProps(_guardReactiveProps({
                  market: __props.market,
                  postOnly: _unref(formValues)[_unref(TradeField).PostOnly],
                  tradingType: _unref(formValues)[_unref(TradeField).TradingType]
                })), null, 16)
              ]),
              feeRate: _withCtx(() => [
                _createVNode(_component_PartialsTradingOrderDetailsFeeRate, _normalizeProps(_guardReactiveProps({
                  fees: __props.fees,
                  market: __props.market,
                  notionalValue: __props.notionalValue,
                  postOnly: _unref(formValues)[_unref(TradeField).PostOnly],
                  tradingType: _unref(formValues)[_unref(TradeField).TradingType]
                })), null, 16)
              ]),
              feeRebate: _withCtx(() => [
                _createVNode(_component_PartialsTradingOrderDetailsFeeRebate, _normalizeProps(_guardReactiveProps({ market: __props.market, notionalValue: __props.notionalValue })), null, 16)
              ]),
              expectedPts: _withCtx(() => [
                _createVNode(_component_PartialsTradingOrderDetailsExpectedPoints, _normalizeProps(_guardReactiveProps({
                  fees: __props.fees,
                  market: __props.market,
                  postOnly: _unref(formValues)[_unref(TradeField).PostOnly],
                  tradingType: _unref(formValues)[_unref(TradeField).TradingType]
                })), null, 16)
              ]),
              _: 1
            }, 16))
          ]),
          _: 1
        }))
      ]);
    };
  }
});
