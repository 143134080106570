import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "rounded flex h-9" };
const _hoisted_2 = {
  key: 0,
  class: "m-auto"
};
const _hoisted_3 = {
  key: 1,
  class: "m-auto"
};
import {
  DerivativeOrderSide,
  MarketType,
  SpotOrderSide
} from "@injectivelabs/sdk-ui-ts";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "OrderTypeSelect",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const formValues = useFormValues();
    const isSpot = props.market.type === MarketType.Spot;
    const filterList = isSpot ? [SpotOrderSide.Buy, SpotOrderSide.Sell] : [DerivativeOrderSide.Buy, DerivativeOrderSide.Sell];
    const { value: orderType } = useStringField({
      name: TradeField.OrderType,
      initialValue: isSpot ? SpotOrderSide.Buy : DerivativeOrderSide.Buy,
      rule: "required"
    });
    function handleSelectOrderType() {
      formValues.value[TradeField.ProportionalPercentage] = 0;
    }
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filterList), (orderSide) => {
          return _openBlock(), _createBlock(_component_AppSelectButton, {
            key: `trade-form-order-${orderSide}`,
            modelValue: _unref(orderType),
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event) => _isRef(orderType) ? orderType.value = $event : null),
              handleSelectOrderType
            ],
            class: "w-1/2 bg-gray-1000 shadow-sm",
            value: orderSide
          }, {
            default: _withCtx(({ active }) => [
              _createElementVNode("span", {
                class: _normalizeClass(["uppercase rounded text-xs tracking-wide border px-5 h-9 flex", {
                  "text-gray-300 border-transparent": !active,
                  "text-green-500 border-green-500": active && (orderSide === _unref(DerivativeOrderSide).Buy || orderSide === _unref(SpotOrderSide).Buy),
                  "text-red-500 border-red-500": active && (orderSide === _unref(DerivativeOrderSide).Sell || orderSide === _unref(SpotOrderSide).Sell)
                }])
              }, [
                orderSide === _unref(DerivativeOrderSide).Buy || orderSide === _unref(SpotOrderSide).Buy ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.buy_asset", {
                  asset: __props.market.baseToken.symbol
                })), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.sell_asset", {
                  asset: __props.market.baseToken.symbol
                })), 1))
              ], 2)
            ]),
            _: 2
          }, 1032, ["modelValue", "value"]);
        }), 128))
      ]);
    };
  }
});
