import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TextInfo.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  "data-cy": "trading-page-details-fee-rebate-value-text-content",
  class: "font-mono flex items-start break-all"
};
const _hoisted_2 = { class: "text-gray-500 ml-1 break-normal" };
const _hoisted_3 = {
  key: 1,
  class: "text-gray-500 ml-1"
};
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "FeeRebate",
  props: {
    notionalValue: {
      type: Object,
      default: ZERO_IN_BASE
    },
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const { makerFeeRate } = useTradeFee(computed(() => props.market));
    const marketHasNegativeMakerFee = computed(
      () => new BigNumberInBase(props.market.makerFeeRate).lt(0)
    );
    const feeRebates = computed(() => {
      if (!props.notionalValue) {
        return ZERO_IN_BASE;
      }
      if (props.notionalValue.isNaN()) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(
        props.notionalValue.times(makerFeeRate.value).abs()
      ).times(
        0.6
        /* Only 60% of the fees are getting returned */
      );
    });
    const { valueToString: feeRebatesToFormat } = useBigNumberFormatter(
      feeRebates,
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_CommonTextInfo = __nuxt_component_1;
      return _unref(marketHasNegativeMakerFee) ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
        key: 0,
        title: _ctx.$t("trade.est_fee_rebate"),
        class: "mt-2"
      }, {
        context: _withCtx(() => [
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.est_fee_rebate_note")
          }, null, 8, ["tooltip"])
        ]),
        default: _withCtx(() => [
          _unref(feeRebates).gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createTextVNode(_toDisplayString(_unref(feeRebatesToFormat)) + " ", 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(__props.market.quoteToken.symbol), 1)
          ])) : (_openBlock(), _createElementBlock("span", _hoisted_3, " \u2014 "))
        ]),
        _: 1
      }, 8, ["title"])) : _createCommentVNode("", true);
    };
  }
});
