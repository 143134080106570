import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/MarketInfo.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "text-xs lg:text-right font-mono block",
  "data-cy": "market-info-next-funding-span"
};
import { differenceInSeconds, endOfHour, intervalToDuration } from "date-fns";
export default /* @__PURE__ */ _defineComponent({
  __name: "NextFunding",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const isPerpetualMarket = computed(
      () => props.market.isPerpetual
    );
    const now = ref(0);
    const labelToDisplay = ["hours", "minutes", "seconds"];
    const countdown = computed(() => {
      const difference = intervalToDuration({
        start: now.value,
        end: endOfHour(now.value)
      });
      return Object.entries(difference).map(([label, value]) => {
        if (labelToDisplay.includes(label)) {
          const valueToTwoDigits = value < 10 ? `0${value}` : `${value}`;
          return valueToTwoDigits;
        }
        return void 0;
      }).filter((time) => time).join(":");
    });
    useIntervalFn(() => {
      now.value = Date.now();
      const end = endOfHour(now.value);
      const shouldFetchNewFunding = differenceInSeconds(end, now.value) === 1;
      if (shouldFetchNewFunding) {
        derivativeStore.fetchMarket(props.market.marketId);
      }
    }, 1e3);
    return (_ctx, _cache) => {
      const _component_CommonMarketInfo = __nuxt_component_0;
      return _unref(isPerpetualMarket) ? (_openBlock(), _createBlock(_component_CommonMarketInfo, {
        key: 0,
        title: _ctx.$t("trade.next_funding"),
        tooltip: _ctx.$t("trade.next_funding_tooltip")
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(countdown)), 1)
        ]),
        _: 1
      }, 8, ["title", "tooltip"])) : _createCommentVNode("", true);
    };
  }
});
