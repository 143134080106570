import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TextInfo.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "font-mono flex items-start break-all" };
const _hoisted_2 = { class: "text-gray-500 ml-1 break-normal" };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import {
  cosmosSdkDecToBigNumber,
  getExactDecimalsFromNumber
} from "@injectivelabs/sdk-ts";
import { TradeExecutionType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "ExpectedPoints",
  props: {
    postOnly: Boolean,
    fees: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    tradingType: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const {
      isMarketDisqualified,
      marketTakerMakerExpectedPts,
      marketIncludedInTradingReward
    } = useTradeReward(computed(() => props.market));
    const { makerFeeRate, takerFeeRate } = useTradeFee(computed(() => props.market));
    const tradeTypeMarket = computed(
      () => [TradeExecutionType.Market, TradeExecutionType.StopMarket].includes(
        props.tradingType
      )
    );
    const makerExpectedPts = computed(() => {
      if (makerFeeRate.value.lte(0) || isMarketDisqualified.value || !marketIncludedInTradingReward.value) {
        return ZERO_IN_BASE;
      }
      if (!marketTakerMakerExpectedPts.value) {
        return ZERO_IN_BASE;
      }
      const makerPointsMultiplier = marketTakerMakerExpectedPts.value.makerPointsMultiplier || 1;
      const boostedMultiplier = cosmosSdkDecToBigNumber(makerPointsMultiplier);
      return new BigNumberInBase(props.fees).times(boostedMultiplier);
    });
    const takerExpectedPts = computed(() => {
      if (takerFeeRate.value.lte(0) || isMarketDisqualified.value || !marketIncludedInTradingReward.value) {
        return ZERO_IN_BASE;
      }
      if (!marketTakerMakerExpectedPts.value) {
        return ZERO_IN_BASE;
      }
      const takerPointsMultiplier = marketTakerMakerExpectedPts.value.takerPointsMultiplier || 1;
      const boostedMultiplier = cosmosSdkDecToBigNumber(takerPointsMultiplier);
      return new BigNumberInBase(props.fees).times(boostedMultiplier);
    });
    const expectedPts = computed(() => {
      if (!props.postOnly || tradeTypeMarket.value) {
        return takerExpectedPts.value;
      }
      return makerExpectedPts.value;
    });
    const expectedPtsToFormat = computed(() => {
      if (!tradeTypeMarket.value && props.postOnly) {
        const makerExpectedPtsBasedOnTradingType = tradeTypeMarket.value ? makerExpectedPts.value : makerExpectedPts.value.abs();
        return makerExpectedPtsBasedOnTradingType.toFormat(
          getExactDecimalsFromNumber(makerExpectedPtsBasedOnTradingType.toNumber())
        );
      }
      return takerExpectedPts.value.toFormat(
        getExactDecimalsFromNumber(takerExpectedPts.value.toNumber())
      );
    });
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_CommonTextInfo = __nuxt_component_1;
      return _unref(expectedPts).gte(0) ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
        key: 0,
        title: _ctx.$t("trade.expected_points"),
        class: "mt-2"
      }, {
        context: _withCtx(() => [
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.expected_points_note")
          }, null, 8, ["tooltip"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createTextVNode(_toDisplayString(`${_unref(expectedPtsToFormat)}`) + " ", 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("pts")), 1)
          ])
        ]),
        _: 1
      }, 8, ["title"])) : _createCommentVNode("", true);
    };
  }
});
