import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Sidebar/MarketsFilter.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/SortableHeaderItem.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Header.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Sidebar/MarketsTableRow.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Common/Table/Body.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center flex-1 text-2xs" };
const _hoisted_2 = { class: "text-gray-200 font-normal order-last whitespace-nowrap" };
const _hoisted_3 = { class: "text-gray-200 font-normal ml-1" };
const _hoisted_4 = { class: "flex items-center justify-end flex-1 text-2xs whitespace-nowrap" };
const _hoisted_5 = { class: "font-normal text-gray-200" };
const _hoisted_6 = { class: "text-gray-200 font-normal" };
const _hoisted_7 = {
  key: 0,
  class: "mt-1 text-2xs text-gray-500 text-center"
};
const _hoisted_8 = {
  key: 1,
  class: "mt-1 text-2xs text-gray-500 text-center"
};
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { marketIsPartOfType, marketIsPartOfSearch } from "@/app/utils/market";
var SortableKeys = /* @__PURE__ */ ((SortableKeys2) => {
  SortableKeys2["Market"] = "market";
  SortableKeys2["Change"] = "change";
  SortableKeys2["Volume"] = "volume";
  return SortableKeys2;
})(SortableKeys || {});
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketsTable",
  props: {
    market: {
      type: Object,
      required: true
    },
    markets: {
      type: Array,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const appStore = useAppStore();
    const activeType = ref("");
    const search = ref("");
    const ascending = ref(false);
    const sortBy = ref("volume" /* Volume */);
    const filteredMarkets = computed(() => {
      return props.markets.filter(({ market }) => {
        const isPartOfSearch = marketIsPartOfSearch(search.value, market);
        const isPartOfType = marketIsPartOfType({
          market,
          favoriteMarkets: appStore.favoriteMarkets,
          activeType: activeType.value
        });
        return isPartOfType && isPartOfSearch;
      }).filter((m) => m.summary);
    });
    const sortedMarkets = computed(() => {
      if (sortBy.value.trim() === "") {
        return filteredMarkets.value;
      }
      const markets = [...filteredMarkets.value].sort(
        (m1, m2) => {
          if (sortBy.value === "market" /* Market */) {
            return m2.market.ticker.localeCompare(m1.market.ticker);
          }
          if (sortBy.value === "change" /* Change */) {
            if (new BigNumberInBase(m2.summary.change).eq(m1.summary.change)) {
              return m1.market.ticker.localeCompare(m2.market.ticker);
            }
            return new BigNumberInBase(m2.summary.change).minus(m1.summary.change).toNumber();
          }
          if (new BigNumberInBase(m2.volumeInUsd).eq(m1.volumeInUsd)) {
            return m1.market.ticker.localeCompare(m2.market.ticker);
          }
          return m2.volumeInUsd.minus(m1.volumeInUsd).toNumber();
        }
      );
      return ascending.value ? markets.reverse() : markets;
    });
    return (_ctx, _cache) => {
      const _component_PartialsTradingSidebarMarketsFilter = __nuxt_component_0;
      const _component_AppSortableHeaderItem = __nuxt_component_1;
      const _component_CommonTableHeader = __nuxt_component_2;
      const _component_PartialsTradingSidebarMarketsTableRow = __nuxt_component_3;
      const _component_BaseIcon = __nuxt_component_4;
      const _component_CommonEmptyList = __nuxt_component_5;
      const _component_CommonTableBody = __nuxt_component_6;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_PartialsTradingSidebarMarketsFilter, {
          "active-type": _unref(activeType),
          "onUpdate:activeType": _cache[0] || (_cache[0] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
          search: _unref(search),
          "onUpdate:search": _cache[1] || (_cache[1] = ($event) => _isRef(search) ? search.value = $event : null),
          class: "mb-2"
        }, null, 8, ["active-type", "search"]),
        _createVNode(_component_CommonTableHeader, { classes: "flex flex-wrap mb-2 max-3xl:px-0" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AppSortableHeaderItem, {
                "sort-by": _unref(sortBy),
                "onUpdate:sortBy": _cache[2] || (_cache[2] = ($event) => _isRef(sortBy) ? sortBy.value = $event : null),
                ascending: _unref(ascending),
                "onUpdate:ascending": _cache[3] || (_cache[3] = ($event) => _isRef(ascending) ? ascending.value = $event : null),
                value: "market" /* Market */
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.market")) + " / ", 1)
                ]),
                _: 1
              }, 8, ["sort-by", "ascending", "value"]),
              _createVNode(_component_AppSortableHeaderItem, {
                "sort-by": _unref(sortBy),
                "onUpdate:sortBy": _cache[4] || (_cache[4] = ($event) => _isRef(sortBy) ? sortBy.value = $event : null),
                ascending: _unref(ascending),
                "onUpdate:ascending": _cache[5] || (_cache[5] = ($event) => _isRef(ascending) ? ascending.value = $event : null),
                "data-cy": "markets-volume_24h-table-header",
                value: "volume" /* Volume */,
                "icon-class": "order-last"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.volume")), 1)
                ]),
                _: 1
              }, 8, ["sort-by", "ascending", "value"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.price")) + " / ", 1),
              _createVNode(_component_AppSortableHeaderItem, {
                "sort-by": _unref(sortBy),
                "onUpdate:sortBy": _cache[6] || (_cache[6] = ($event) => _isRef(sortBy) ? sortBy.value = $event : null),
                ascending: _unref(ascending),
                "onUpdate:ascending": _cache[7] || (_cache[7] = ($event) => _isRef(ascending) ? ascending.value = $event : null),
                "data-cy": "markets-volume_24h-table-header",
                value: "change" /* Change */,
                "icon-class": "order-last"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("trade.market_change_24h")), 1)
                ]),
                _: 1
              }, 8, ["sort-by", "ascending", "value"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_CommonTableBody, {
          "show-empty": _unref(sortedMarkets).length === 0,
          class: "rounded overflow-hidden"
        }, {
          empty: _withCtx(() => [
            _createVNode(_component_CommonEmptyList, {
              class: "min-h-3xs bg-gray-900",
              "data-cy": "markets-no-data-table",
              message: _unref(activeType) === _unref(MarketType).Favorite ? _ctx.$t("markets.emptyHeaderFavorites") : _ctx.$t("markets.emptyHeader")
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_BaseIcon, {
                  name: "star-border",
                  class: "text-gray-500 w-8 h-8"
                })
              ]),
              default: _withCtx(() => [
                _unref(activeType) === _unref(MarketType).Favorite ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("markets.emptyDescriptionFavorites")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("markets.emptyDescription")), 1))
              ]),
              _: 1
            }, 8, ["message"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedMarkets), (marketSummary, index) => {
              return _openBlock(), _createBlock(_component_PartialsTradingSidebarMarketsTableRow, _mergeProps({
                ..._ctx.$attrs,
                market: marketSummary.market,
                summary: marketSummary.summary,
                volumeInUsd: marketSummary.volumeInUsd,
                isCurrentMarket: __props.market.marketId === marketSummary.market.marketId
              }, {
                key: `market-row-${index}-${marketSummary.market.marketId}`
              }), null, 16);
            }), 128))
          ]),
          _: 1
        }, 8, ["show-empty"])
      ]);
    };
  }
});
