import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Market/Orderbook/Record.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Market/Orderbook/SummaryPopup.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-col flex-wrap overflow-y-hidden w-full px-2" };
const _hoisted_2 = { class: "flex h-full w-full" };
const _hoisted_3 = { class: "orderbook-middle-h bg-gray-1000 flex flex-col items-center justify-center border-t border-b" };
const _hoisted_4 = { class: "w-full flex items-center justify-center" };
const _hoisted_5 = { class: "text-gray-500 underline font-mono text-base lg:text-sm 4xl:text-md cursor-pointer mx-2" };
const _hoisted_6 = { class: "flex h-full w-full" };
import { createPopperLite } from "@popperjs/core";
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import {
  Change,
  MarketType,
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import { SpotOrderSide, DerivativeOrderSide } from "@injectivelabs/sdk-ts";
import { vScroll } from "@vueuse/components";
import { getAggregationPrice } from "@/app/client/utils/orderbook";
import { computeOrderbookSummary as computeOrderbookSummarySpot } from "@/app/client/utils/spot";
import { computeOrderbookSummary as computeOrderbookSummaryDerivative } from "@/app/client/utils/derivatives";
import { OrderbookLayout, TradingLayout } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    aggregation: {
      type: Number,
      required: true
    },
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const appStore = useAppStore();
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const isSpot = props.market.type === MarketType.Spot;
    const subaccountOrders = computed(
      () => isSpot ? spotStore.subaccountOrders : derivativeStore.subaccountOrders
    );
    const buys = computed(() => {
      return isSpot ? spotStore.buys : derivativeStore.buys;
    });
    const sells = computed(() => {
      return isSpot ? spotStore.sells : derivativeStore.sells;
    });
    const autoScrollSellsLocked = ref(false);
    const autoScrollBuysLocked = ref(false);
    const buyHoverPosition = ref(null);
    const sellHoverPosition = ref(null);
    const popper = ref(void 0);
    const orderbookSummaryRef = ref(void 0);
    const buyOrdersContainerRef = ref(void 0);
    const sellOrdersContainerRef = ref(void 0);
    const sellRecordListRef = ref([]);
    const buyRecordListRef = ref([]);
    const {
      lastTradedPrice: spotLastTradedPrice,
      lastTradedPriceChange: spotLastTradedPriceChange
    } = useSpotLastPrice(computed(() => props.market));
    const {
      markPrice,
      lastTradedPrice: derivativeLastTradedPrice,
      lastTradedPriceChange: derivativeLastTradedPriceChange
    } = useDerivativeLastPrice(computed(() => props.market));
    const lastTradedPrice = computed(
      () => isSpot ? spotLastTradedPrice.value : derivativeLastTradedPrice.value
    );
    const lastTradedPriceChange = computed(
      () => isSpot ? spotLastTradedPriceChange.value : derivativeLastTradedPriceChange.value
    );
    const { valueToFixed: lastTradedPriceToFormat } = useBigNumberFormatter(
      lastTradedPrice,
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const { valueToFixed: markPriceToFormat } = useBigNumberFormatter(
      computed(() => markPrice.value),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const buyUserOrderPrices = computed(
      () => subaccountOrders.value.reduce((records, { orderSide, price }) => {
        return orderSide === DerivativeOrderSide.Buy || orderSide === SpotOrderSide.Buy ? [...records, price] : records;
      }, [])
    );
    const sellUserOrderPrices = computed(
      () => subaccountOrders.value.reduce((records, { orderSide, price }) => {
        return orderSide === DerivativeOrderSide.Sell ? [...records, price] : records;
      }, [])
    );
    const midOrderbookPrice = computed(() => {
      const [sell] = sells.value;
      const [buy] = buys.value;
      const highestBuy = new BigNumberInBase(buy ? buy.price : 0);
      const lowestSell = new BigNumberInBase(sell ? sell.price : 0);
      const sum = highestBuy.plus(lowestSell);
      if (sum.lte(0)) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInWei(sum.div(2)).toBase(props.market.quoteToken.decimals);
    });
    const aggregatedBuyOrders = computed(() => {
      const orders = {};
      buys.value.forEach((record) => {
        const price = new BigNumberInBase(
          isSpot ? new BigNumberInBase(record.price).toWei(
            props.market.baseToken.decimals - props.market.quoteToken.decimals
          ) : new BigNumberInWei(record.price).toBase(
            props.market.quoteToken.decimals
          )
        );
        const aggregatedPrice = getAggregationPrice({
          price,
          aggregation: props.aggregation,
          isBuy: true
        });
        const aggregatedPriceKey = aggregatedPrice.toFormat();
        orders[aggregatedPriceKey] = [
          ...orders[aggregatedPriceKey] || [],
          {
            ...record,
            aggregatedPrice: aggregatedPrice.toFixed()
          }
        ];
      });
      return Object.entries(orders).map(([, orderGroup]) => {
        const [firstOrder] = orderGroup;
        const quantity = orderGroup.reduce(
          (sum, order) => {
            return sum.plus(new BigNumberInWei(order.quantity));
          },
          new BigNumberInWei(0)
        );
        const notional = orderGroup.reduce(
          (sum, order) => {
            const notional2 = new BigNumberInWei(order.quantity).times(order.price).toBase(props.market.quoteToken.decimals);
            return sum.plus(notional2);
          },
          new BigNumberInBase(0)
        );
        const aggregatePrices = orderGroup.map(({ price }) => price);
        if (isSpot) {
          return {
            ...firstOrder,
            aggregatePrices,
            quantity
          };
        }
        return {
          ...firstOrder,
          aggregatePrices,
          notional,
          quantity
        };
      });
    });
    const aggregatedSellOrders = computed(() => {
      const orders = {};
      sells.value.forEach((record) => {
        const price = new BigNumberInBase(
          isSpot ? new BigNumberInBase(record.price).toWei(
            props.market.baseToken.decimals - props.market.quoteToken.decimals
          ) : new BigNumberInWei(record.price).toBase(
            props.market.quoteToken.decimals
          )
        );
        const aggregatedPrice = getAggregationPrice({
          price,
          aggregation: props.aggregation,
          isBuy: false
        });
        const aggregatedPriceKey = aggregatedPrice.toFormat();
        orders[aggregatedPriceKey] = [
          ...orders[aggregatedPriceKey] || [],
          {
            ...record,
            aggregatedPrice: aggregatedPrice.toFixed()
          }
        ];
      });
      return Object.entries(orders).reverse().map(([, orderGroup]) => {
        const [firstOrder] = orderGroup;
        const quantity = orderGroup.reduce(
          (sum, order) => {
            return sum.plus(new BigNumberInWei(order.quantity));
          },
          new BigNumberInWei(0)
        );
        const aggregatePrices = orderGroup.map(({ price }) => price);
        return {
          ...firstOrder,
          aggregatePrices,
          quantity
        };
      });
    });
    const buysTotalNotional = computed(() => {
      const threshold = new BigNumberInBase(1).minus(
        new BigNumberInBase(20).div(100)
      );
      const filteredBuys = aggregatedBuyOrders.value.filter((buy) => {
        return new BigNumberInWei(buy.price).toBase(props.market.quoteToken.decimals).div(midOrderbookPrice.value).gte(threshold);
      }).sort((a, b) => {
        const aNotional = new BigNumberInWei(a.quantity).times(a.price);
        const bNotional = new BigNumberInWei(b.quantity).times(b.price);
        return new BigNumberInBase(bNotional).minus(aNotional).toNumber();
      });
      if (filteredBuys.length === 0) {
        return ZERO_IN_BASE;
      }
      const [highestNotionalBuy] = filteredBuys;
      return new BigNumberInWei(highestNotionalBuy.quantity).times(highestNotionalBuy.price).toBase(props.market.quoteToken.decimals);
    });
    const sellsHighestBaseQuantity = computed(() => {
      const threshold = new BigNumberInBase(1).minus(
        new BigNumberInBase(20).div(100)
      );
      const filteredSells = aggregatedSellOrders.value.filter((sell) => {
        return midOrderbookPrice.value.div(
          new BigNumberInWei(sell.price).toBase(
            props.market.quoteToken.decimals
          )
        ).gte(threshold);
      }).sort((a, b) => {
        return new BigNumberInBase(b.quantity).minus(a.quantity).toNumber();
      });
      if (filteredSells.length === 0) {
        return ZERO_IN_BASE;
      }
      const [highestSell] = filteredSells;
      const highestSellQuantity = isSpot ? new BigNumberInWei(highestSell.quantity).toBase(
        props.market.baseToken.decimals
      ) : new BigNumberInBase(highestSell.quantity);
      return highestSellQuantity;
    });
    const buysWithDepth = computed(() => {
      const sortedAggregatedBuyOrders = [...aggregatedBuyOrders.value].sort(
        (v1, v2) => {
          const v1Price = new BigNumberInWei(v1.price);
          const v2Price = new BigNumberInWei(v2.price);
          return v2Price.minus(v1Price).toNumber();
        }
      );
      return sortedAggregatedBuyOrders.map((record) => {
        const total = new BigNumberInWei(record.quantity).times(record.price).toBase(props.market.quoteToken.decimals);
        return {
          ...record,
          total,
          depth: total.dividedBy(buysTotalNotional.value).times(100).toNumber()
        };
      });
    });
    const buyOrdersSummary = computed(() => {
      if (buysWithDepth.value.length === 0 || buyHoverPosition.value === null) {
        return;
      }
      if (isSpot) {
        const buyOrdersSummary2 = buysWithDepth.value.slice(0, Number(buyHoverPosition.value) + 1).reduce(computeOrderbookSummarySpot, {
          quantity: new BigNumberInWei(0),
          total: new BigNumberInBase(0)
        });
        return {
          ...buyOrdersSummary2,
          quantity: buyOrdersSummary2.quantity.toBase(
            props.market.baseToken.decimals
          )
        };
      }
      return buysWithDepth.value.slice(0, Number(buyHoverPosition.value) + 1).reduce(computeOrderbookSummaryDerivative, {
        quantity: new BigNumberInBase(0),
        total: new BigNumberInBase(0)
      });
    });
    const sellsWithDepth = computed(() => {
      const sortedAggregatedSellOrders = [...aggregatedSellOrders.value].sort(
        (v1, v2) => {
          const v1Price = new BigNumberInWei(v1.price);
          const v2Price = new BigNumberInWei(v2.price);
          return v1Price.minus(v2Price).toNumber();
        }
      );
      return sortedAggregatedSellOrders.map((record) => {
        const baseQuantity = isSpot ? new BigNumberInWei(record.quantity).toBase(
          props.market.baseToken.decimals
        ) : new BigNumberInWei(record.quantity);
        const total = new BigNumberInWei(record.quantity).times(record.price).toBase(props.market.quoteToken.decimals);
        return {
          ...record,
          total,
          depth: baseQuantity.dividedBy(sellsHighestBaseQuantity.value).times(100).toNumber()
        };
      }).reverse();
    });
    const sellOrdersSummary = computed(() => {
      if (sellsWithDepth.value.length === 0 || sellHoverPosition.value === null) {
        return;
      }
      if (isSpot) {
        const sellOrdersSummary2 = sellsWithDepth.value.slice(Number(sellHoverPosition.value)).reduce(computeOrderbookSummarySpot, {
          quantity: new BigNumberInWei(0),
          total: new BigNumberInBase(0)
        });
        const quantity = sellOrdersSummary2.quantity.toBase(
          props.market.baseToken.decimals
        );
        return {
          ...sellOrdersSummary2,
          quantity
        };
      }
      return sellsWithDepth.value.slice(Number(sellHoverPosition.value)).reduce(computeOrderbookSummaryDerivative, {
        quantity: new BigNumberInBase(0),
        total: new BigNumberInBase(0)
      });
    });
    const orderBookSummary = computed(() => {
      if (buyHoverPosition.value !== null) {
        return buyOrdersSummary.value;
      }
      if (sellHoverPosition.value !== null) {
        return sellOrdersSummary.value;
      }
      return void 0;
    });
    const popperOptions = computed(() => ({
      placement: appStore.userState.tradingLayout === TradingLayout.Right ? "left" : "right"
    }));
    watch(
      () => props.aggregation,
      () => nextTick(() => {
        onScrollSells();
        onScrollBuys();
      })
    );
    watch(sells, () => nextTick(onScrollSells));
    watch(buys, () => nextTick(onScrollBuys));
    onMounted(() => {
      nextTick(() => {
        onScrollSells();
        onScrollBuys();
      });
    });
    onBeforeUpdate(() => {
      buyRecordListRef.value = [];
      sellRecordListRef.value = [];
    });
    function onScrollSells() {
      const el = sellOrdersContainerRef.value;
      if (el && !autoScrollSellsLocked.value) {
        el.scrollTop = el.scrollHeight;
      }
    }
    function onScrollBuys() {
      const el = buyOrdersContainerRef.value;
      if (el && !autoScrollBuysLocked.value) {
        el.scrollTop = 0;
      }
    }
    function handleSellOrderHover(position) {
      sellHoverPosition.value = position;
      if (position !== null) {
        if (sellRecordListRef.value && sellRecordListRef.value[position]) {
          const hoverElement = sellRecordListRef.value[position].element;
          popper.value = createPopperLite(
            hoverElement,
            orderbookSummaryRef.value,
            popperOptions.value
          );
        }
        nextTick(() => {
          if (orderbookSummaryRef.value) {
            orderbookSummaryRef.value.setAttribute("data-show", "");
          }
        });
      } else {
        if (popper.value) {
          popper.value.destroy();
        }
        if (orderbookSummaryRef.value) {
          orderbookSummaryRef.value.removeAttribute("data-show");
        }
      }
    }
    function handleBuyOrderHover(position) {
      buyHoverPosition.value = position;
      if (position !== null) {
        if (buyRecordListRef.value && buyRecordListRef.value[position]) {
          const hoverElement = buyRecordListRef.value[position].element;
          popper.value = createPopperLite(
            hoverElement,
            orderbookSummaryRef.value,
            popperOptions.value
          );
        }
        nextTick(() => {
          if (orderbookSummaryRef.value) {
            orderbookSummaryRef.value.setAttribute("data-show", "");
          }
        });
      } else {
        if (popper.value) {
          popper.value.destroy();
        }
        if (orderbookSummaryRef.value) {
          orderbookSummaryRef.value.removeAttribute("data-show");
        }
      }
    }
    function hidePopperOnScroll(state) {
      if (orderbookSummaryRef.value) {
        if (state.isScrolling.value) {
          orderbookSummaryRef.value.removeAttribute("data-show");
        } else {
          orderbookSummaryRef.value.setAttribute("data-show", "");
        }
      }
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingMarketOrderbookRecord = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      const _component_CommonInfoTooltip = __nuxt_component_2;
      const _component_PartialsTradingMarketOrderbookSummaryPopup = __nuxt_component_3;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
          _unref(appStore).userState.orderbookLayout !== _unref(OrderbookLayout).Buys ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "sellOrdersContainerRef",
            ref: sellOrdersContainerRef,
            class: _normalizeClass(["overflow-y-scroll overflow-x-hidden w-full", {
              "orderbook-half-h": _unref(appStore).userState.orderbookLayout !== _unref(OrderbookLayout).Sells,
              "orderbook-full-h": _unref(appStore).userState.orderbookLayout === _unref(OrderbookLayout).Sells
            }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("ul", {
                class: "list-order-book w-full mt-auto",
                onMouseenter: _cache[0] || (_cache[0] = ($event) => autoScrollSellsLocked.value = true),
                onMouseleave: _cache[1] || (_cache[1] = ($event) => autoScrollSellsLocked.value = false)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sellsWithDepth), (sell, index) => {
                  return _openBlock(), _createBlock(_component_PartialsTradingMarketOrderbookRecord, _mergeProps({
                    key: `order-book-sell-${sell.aggregatedPrice || sell.price}-${__props.aggregation}`,
                    ref_for: true,
                    ref_key: "sellRecordListRef",
                    ref: sellRecordListRef,
                    class: ["bg-gray-750 bg-opacity-20 hover:bg-purple-200 hover:bg-opacity-5", {
                      active: _unref(sellHoverPosition) !== null && index >= _unref(sellHoverPosition)
                    }]
                  }, {
                    market: __props.market,
                    position: index,
                    aggregation: __props.aggregation,
                    type: isSpot ? _unref(SpotOrderSide).Sell : _unref(DerivativeOrderSide).Sell,
                    userOrders: _unref(sellUserOrderPrices),
                    record: sell
                  }, {
                    position: index,
                    aggregation: __props.aggregation,
                    type: _unref(DerivativeOrderSide).Sell,
                    "user-orders": _unref(sellUserOrderPrices),
                    record: sell,
                    "data-cy": "orderbook-sell-list-item",
                    "onUpdate:activePosition": handleSellOrderHover
                  }), null, 16, ["class", "position", "aggregation", "type", "user-orders", "record"]);
                }), 128))
              ], 32)
            ])
          ], 2)), [
            [_unref(vScroll), hidePopperOnScroll]
          ]) : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              !isSpot ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([{
                  "text-red-500": _unref(lastTradedPriceChange) === _unref(Change).Decrease,
                  "text-green-500": _unref(lastTradedPriceChange) !== _unref(Change).Decrease
                }, "font-bold font-mono text-base lg:text-lg 4xl:text-xl"]),
                "data-cy": "orderbook-last-traded-price-text-content"
              }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)) : _createCommentVNode("", true),
              [_unref(Change).Increase, _unref(Change).Decrease].includes(_unref(lastTradedPriceChange)) ? (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 1,
                name: "arrow",
                class: _normalizeClass(["transform w-3 h-3 lg:w-4 lg:h-4 4xl:w-5 4xl:h-5", {
                  "text-red-500 -rotate-90": _unref(lastTradedPriceChange) === _unref(Change).Decrease,
                  "text-green-500 rotate-90": _unref(lastTradedPriceChange) === _unref(Change).Increase,
                  "ml-2 mr-4": !isSpot
                }])
              }, null, 8, ["class"])) : _createCommentVNode("", true),
              isSpot ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass([{
                  "text-red-500": _unref(lastTradedPriceChange) === _unref(Change).Decrease,
                  "text-green-500": _unref(lastTradedPriceChange) !== _unref(Change).Decrease
                }, "font-bold font-mono text-base lg:text-lg 4xl:text-xl"]),
                "data-cy": "orderbook-last-traded-price-text-content"
              }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)) : _createCommentVNode("", true),
              !isSpot ? (_openBlock(), _createBlock(_component_CommonInfoTooltip, {
                key: 3,
                tooltip: _ctx.$t("trade.mark_price_tooltip_verbose"),
                "data-cy": "orderbook-mark-price-text-content"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(markPriceToFormat)), 1)
                ]),
                _: 1
              }, 8, ["tooltip"])) : _createCommentVNode("", true)
            ])
          ]),
          _unref(appStore).userState.orderbookLayout !== _unref(OrderbookLayout).Sells ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 1,
            ref_key: "buyOrdersContainerRef",
            ref: buyOrdersContainerRef,
            class: _normalizeClass(["overflow-y-scroll overflow-x-hidden w-full", {
              "orderbook-half-h": _unref(appStore).userState.orderbookLayout !== _unref(OrderbookLayout).Buys,
              "orderbook-full-h": _unref(appStore).userState.orderbookLayout === _unref(OrderbookLayout).Buys
            }])
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("ul", {
                class: "list-order-book w-full mb-auto",
                onMouseenter: _cache[2] || (_cache[2] = ($event) => autoScrollBuysLocked.value = true),
                onMouseleave: _cache[3] || (_cache[3] = ($event) => autoScrollBuysLocked.value = false)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(buysWithDepth), (buy, index) => {
                  return _openBlock(), _createBlock(_component_PartialsTradingMarketOrderbookRecord, _mergeProps({
                    key: `order-book-buy-${buy.aggregatedPrice || buy.price}-${__props.aggregation}`,
                    ref_for: true,
                    ref_key: "buyRecordListRef",
                    ref: buyRecordListRef,
                    class: ["bg-gray-750 bg-opacity-20 hover:bg-purple-200 hover:bg-opacity-5", {
                      active: _unref(buyHoverPosition) !== null && index <= _unref(buyHoverPosition)
                    }]
                  }, {
                    aggregation: __props.aggregation,
                    market: __props.market,
                    position: index,
                    record: buy,
                    type: isSpot ? _unref(SpotOrderSide).Buy : _unref(DerivativeOrderSide).Buy,
                    userOrders: _unref(buyUserOrderPrices)
                  }, {
                    "data-cy": "orderbook-buy-list-item",
                    "onUpdate:activePosition": handleBuyOrderHover
                  }), null, 16, ["class"]);
                }), 128))
              ], 32)
            ])
          ], 2)), [
            [_unref(vScroll), hidePopperOnScroll]
          ]) : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          ref_key: "orderbookSummaryRef",
          ref: orderbookSummaryRef,
          class: "orderbook-summary"
        }, [
          _unref(orderBookSummary) ? (_openBlock(), _createBlock(_component_PartialsTradingMarketOrderbookSummaryPopup, {
            key: 0,
            market: __props.market,
            summary: _unref(orderBookSummary)
          }, null, 8, ["market", "summary"])) : _createCommentVNode("", true)
        ], 512)
      ], 64);
    };
  }
});
