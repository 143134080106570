import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex items-center justify-start gap-4" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = {
  key: 0,
  class: "flex items-center justify-start"
};
const _hoisted_5 = { class: "text-xs uppercase tracking-wide cursor-pointer" };
const _hoisted_6 = {
  key: 0,
  class: "text-blue-500"
};
const _hoisted_7 = {
  key: 1,
  class: "text-gray-500"
};
const _hoisted_8 = { class: "text-xs uppercase" };
import { TradeExecutionType, TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "TradeExecutionTypeButtons",
  emits: ["form:reset"],
  setup(__props, { emit }) {
    const { t } = useLang();
    const route = useRoute();
    const filterList = [TradeExecutionType.LimitFill, TradeExecutionType.Market];
    const dropdownOptions = [
      {
        display: t("trade.stopLimit"),
        value: TradeExecutionType.StopLimit
      },
      {
        display: t("trade.stopMarket"),
        value: TradeExecutionType.StopMarket
      }
    ];
    const isSpot = route.name === "spot-spot";
    const { value: tradingType } = useStringField({
      name: TradeField.TradingType,
      initialValue: TradeExecutionType.LimitFill
    });
    function resetForm() {
      emit("form:reset");
    }
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      const _component_AppSelect = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(filterList, (tradeType) => {
          return _createVNode(_component_AppSelectButton, {
            key: `trade-type-${tradeType}`,
            modelValue: _unref(tradingType),
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event) => _isRef(tradingType) ? tradingType.value = $event : null),
              resetForm
            ],
            value: tradeType
          }, {
            default: _withCtx(({ active }) => [
              _createElementVNode("span", {
                class: _normalizeClass(["text-xs uppercase tracking-wide cursor-pointer", [active ? "text-blue-500" : "text-gray-500"]])
              }, [
                tradeType === _unref(TradeExecutionType).LimitFill ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.limit")), 1)) : _createCommentVNode("", true),
                tradeType === _unref(TradeExecutionType).Market ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.market")), 1)) : _createCommentVNode("", true)
              ], 2)
            ]),
            _: 2
          }, 1032, ["modelValue", "value"]);
        }), 64)),
        !isSpot ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_AppSelect, {
            modelValue: _unref(tradingType),
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event) => _isRef(tradingType) ? tradingType.value = $event : null),
              resetForm
            ],
            "wrapper-class": "h-6",
            options: dropdownOptions
          }, {
            default: _withCtx(({ selected }) => [
              _createElementVNode("div", _hoisted_5, [
                selected ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(selected.display), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("trade.stopLimit")), 1))
              ])
            ]),
            option: _withCtx(({ option }) => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(option.display), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])) : _createCommentVNode("", true)
      ]);
    };
  }
});
