import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TextInfo.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Drawer.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex justify-between text-sm" };
const _hoisted_2 = { class: "mt-4 text-xs" };
const _hoisted_3 = { class: "flex items center justify-between" };
const _hoisted_4 = /* @__PURE__ */ _createElementVNode("span", null, "Buy order", -1);
const _hoisted_5 = { class: "flex items center justify-between" };
const _hoisted_6 = /* @__PURE__ */ _createElementVNode("span", null, "Locked base quantity", -1);
const _hoisted_7 = { class: "flex items center justify-between" };
const _hoisted_8 = /* @__PURE__ */ _createElementVNode("span", null, "Amount for calculation", -1);
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = { class: "flex items center justify-between" };
const _hoisted_12 = /* @__PURE__ */ _createElementVNode("span", null, "Average price", -1);
const _hoisted_13 = { key: 0 };
const _hoisted_14 = { key: 1 };
const _hoisted_15 = { class: "flex items center justify-between" };
const _hoisted_16 = /* @__PURE__ */ _createElementVNode("span", null, "Average price with slippage", -1);
const _hoisted_17 = { key: 0 };
const _hoisted_18 = { key: 1 };
const _hoisted_19 = { class: "flex items center justify-between" };
const _hoisted_20 = /* @__PURE__ */ _createElementVNode("span", null, "Worst price", -1);
const _hoisted_21 = { key: 0 };
const _hoisted_22 = { key: 1 };
const _hoisted_23 = { class: "flex items center justify-between" };
const _hoisted_24 = /* @__PURE__ */ _createElementVNode("span", null, "Worst price with slippage", -1);
const _hoisted_25 = { key: 0 };
const _hoisted_26 = { key: 1 };
const _hoisted_27 = { class: "flex items center justify-between" };
const _hoisted_28 = /* @__PURE__ */ _createElementVNode("span", null, "Fees", -1);
const _hoisted_29 = { class: "flex items center justify-between" };
const _hoisted_30 = /* @__PURE__ */ _createElementVNode("span", null, "Fee Rate", -1);
const _hoisted_31 = { class: "flex items center justify-between" };
const _hoisted_32 = /* @__PURE__ */ _createElementVNode("span", null, "Slippage", -1);
const _hoisted_33 = { key: 0 };
const _hoisted_34 = { key: 1 };
const _hoisted_35 = {
  key: 0,
  class: "flex items center justify-between"
};
const _hoisted_36 = /* @__PURE__ */ _createElementVNode("span", null, "Leverage", -1);
const _hoisted_37 = {
  key: 1,
  class: "flex items center justify-between"
};
const _hoisted_38 = /* @__PURE__ */ _createElementVNode("span", null, "Liquidation Price", -1);
const _hoisted_39 = {
  key: 2,
  class: "flex items center justify-between"
};
const _hoisted_40 = /* @__PURE__ */ _createElementVNode("span", null, "Maintenance margin ratio", -1);
const _hoisted_41 = {
  key: 3,
  class: "flex items center justify-between"
};
const _hoisted_42 = /* @__PURE__ */ _createElementVNode("span", null, "Initial margin ratio", -1);
const _hoisted_43 = { class: "flex items center justify-between" };
const _hoisted_44 = { key: 0 };
const _hoisted_45 = { key: 1 };
const _hoisted_46 = { class: "flex items center justify-between" };
const _hoisted_47 = { key: 0 };
const _hoisted_48 = { key: 1 };
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Debug",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    isBaseAmount: Boolean,
    fees: {
      type: Object,
      required: true
    },
    feeRate: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    notionalValue: {
      type: Object,
      default: ZERO_IN_BASE
    },
    notionalWithFees: {
      type: Object,
      required: true
    },
    liquidationPrice: {
      type: Object,
      default: void 0
    }
  },
  setup(__props) {
    const props = __props;
    const formValues = useFormValues();
    const {
      amountForCalculation: amountForCalculationForSpot,
      averagePrice: averagePriceForSpot,
      averagePriceWithSlippage: averagePriceWithSlippageForSpot,
      slippage: slippageForSpot,
      worstPrice: worstPriceForSpot,
      worstPriceWithSlippage: worstPriceWithSlippageForSpot
    } = useSpotPrice({
      formValues,
      isBaseAmount: computed(() => props.isBaseAmount),
      market: computed(() => props.market)
    });
    const {
      amountForCalculation: amountForCalculationForDerivative,
      averagePrice: averagePriceForDerivative,
      averagePriceWithSlippage: averagePriceWithSlippageForDerivative,
      slippage: slippageForDerivative,
      worstPrice: worstPriceForDerivative,
      worstPriceWithSlippage: worstPriceWithSlippageForDerivative
    } = useDerivativePrice({
      formValues,
      isBaseAmount: computed(() => props.isBaseAmount),
      market: computed(() => props.market)
    });
    const maintenanceMarginRatio = computed(
      () => props.market.maintenanceMarginRatio
    );
    const initialMarginRatio = computed(
      () => props.market.initialMarginRatio
    );
    return (_ctx, _cache) => {
      const _component_CommonTextInfo = __nuxt_component_0;
      const _component_AppDrawer = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppDrawer, null, {
        header: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            _createVNode(_component_CommonTextInfo, {
              title: "Debug",
              lg: ""
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _hoisted_4,
              _createElementVNode("span", null, _toDisplayString(__props.isBuy), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _hoisted_6,
              _createElementVNode("span", null, _toDisplayString(__props.isBaseAmount), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _hoisted_8,
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(amountForCalculationForSpot).toFixed()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(amountForCalculationForDerivative).toFixed()), 1))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _hoisted_12,
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(averagePriceForSpot).toFixed()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(averagePriceForDerivative).toFixed()), 1))
            ]),
            _createElementVNode("div", _hoisted_15, [
              _hoisted_16,
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(averagePriceWithSlippageForSpot).toFixed()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(averagePriceWithSlippageForDerivative).toFixed()), 1))
            ]),
            _createElementVNode("div", _hoisted_19, [
              _hoisted_20,
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(worstPriceForSpot).toFixed()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_unref(worstPriceForDerivative).toFixed()), 1))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _hoisted_24,
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_unref(worstPriceWithSlippageForSpot).toFixed()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_unref(worstPriceWithSlippageForDerivative).toFixed()), 1))
            ]),
            _createElementVNode("div", _hoisted_27, [
              _hoisted_28,
              _createElementVNode("span", null, _toDisplayString(__props.fees.toFixed()), 1)
            ]),
            _createElementVNode("div", _hoisted_29, [
              _hoisted_30,
              _createElementVNode("span", null, _toDisplayString(__props.feeRate.toFixed()), 1)
            ]),
            _createElementVNode("div", _hoisted_31, [
              _hoisted_32,
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(slippageForSpot).toFixed()), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_unref(slippageForDerivative).toFixed()), 1))
            ]),
            !__props.isSpot ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              _hoisted_36,
              _createElementVNode("span", null, _toDisplayString(_unref(formValues)[_unref(TradeField).Leverage]), 1)
            ])) : _createCommentVNode("", true),
            __props.liquidationPrice ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _hoisted_38,
              _createElementVNode("span", null, _toDisplayString(__props.liquidationPrice.toFixed()), 1)
            ])) : _createCommentVNode("", true),
            _unref(maintenanceMarginRatio) ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
              _hoisted_40,
              _createElementVNode("span", null, _toDisplayString(_unref(maintenanceMarginRatio)), 1)
            ])) : _createCommentVNode("", true),
            _unref(initialMarginRatio) ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _hoisted_42,
              _createElementVNode("span", null, _toDisplayString(_unref(initialMarginRatio)), 1)
            ])) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_43, [
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_44, "Notional Value")) : (_openBlock(), _createElementBlock("span", _hoisted_45, "Notional with leverage")),
              _createElementVNode("span", null, _toDisplayString(__props.notionalValue.toFixed()), 1)
            ]),
            _createElementVNode("div", _hoisted_46, [
              __props.isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_47, "Notional with fee")) : (_openBlock(), _createElementBlock("span", _hoisted_48, "Notional with leverage and fee")),
              _createElementVNode("span", null, _toDisplayString(__props.notionalWithFees.toFixed()), 1)
            ])
          ])
        ]),
        _: 1
      });
    };
  }
});
