import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Form/Inputs/Price.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Form/Inputs/BaseAmount.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Form/PercentageOptions.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Form/Inputs/QuoteAmount.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex gap-3" };
const _hoisted_2 = { class: "flex flex-1 flex-col items-end" };
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import {
  TradeField
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    isBaseAmount: Boolean,
    orderTypeReduceOnly: Boolean,
    amountStep: {
      type: String,
      required: true
    },
    baseAvailableBalance: {
      type: Object,
      default: void 0
    },
    feeRate: {
      type: Object,
      default: ZERO_IN_BASE
    },
    fees: {
      type: Object,
      default: void 0
    },
    lastTradedPrice: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    maxAmountOnOrderbook: {
      type: Object,
      required: true
    },
    maxReduceOnly: {
      type: Object,
      default: void 0
    },
    position: {
      type: Object,
      default: void 0
    },
    priceStep: {
      type: String,
      required: true
    },
    quoteAvailableBalance: {
      type: Object,
      required: true
    }
  },
  emits: ["update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const formValues = useFormValues();
    const {
      tradingTypeStopLimit,
      tradingTypeStopMarket,
      tradingTypeLimit: derivativeTradingTypeLimit
    } = useDerivativeFormFormatter(formValues);
    const { tradingTypeLimit: spotTradingTypeLimit } = useSpotFormFormatter(formValues);
    const orderbookOrders = computed(() => {
      const buys = props.isSpot ? spotStore.buys : derivativeStore.buys;
      const sells = props.isSpot ? spotStore.sells : derivativeStore.sells;
      return props.isBuy ? sells : buys;
    });
    const tradingTypeLimit = props.isSpot ? spotTradingTypeLimit : derivativeTradingTypeLimit;
    function updateAmount({
      amount,
      isBaseAmount
    }) {
      emit("update:amount", { amount, isBaseAmount });
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingFormInputsPrice = __nuxt_component_0;
      const _component_PartialsTradingFormInputsBaseAmount = __nuxt_component_1;
      const _component_PartialsTradingFormPercentageOptions = __nuxt_component_2;
      const _component_PartialsTradingFormInputsQuoteAmount = __nuxt_component_3;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", null, [
          _unref(tradingTypeStopLimit) || _unref(tradingTypeStopMarket) ? (_openBlock(), _createBlock(_component_PartialsTradingFormInputsPrice, _mergeProps({ key: 0 }, {
            isBaseAmount: __props.isBaseAmount,
            isSpot: __props.isSpot,
            lastTradedPrice: __props.lastTradedPrice,
            market: __props.market,
            priceStep: __props.priceStep,
            priceFieldName: _unref(TradeField).TriggerPrice
          }, { "onUpdate:amount": updateAmount }), null, 16)) : _createCommentVNode("", true),
          _unref(tradingTypeLimit) || _unref(tradingTypeStopLimit) ? (_openBlock(), _createBlock(_component_PartialsTradingFormInputsPrice, _mergeProps({ key: 1 }, {
            isBaseAmount: __props.isBaseAmount,
            isBuy: __props.isBuy,
            isSpot: __props.isSpot,
            lastTradedPrice: __props.lastTradedPrice,
            market: __props.market,
            orderbookOrders: _unref(orderbookOrders),
            priceStep: __props.priceStep,
            tradingTypeLimit: _unref(tradingTypeLimit),
            tradingTypeStopLimit: _unref(tradingTypeStopLimit),
            priceFieldName: _unref(TradeField).LimitPrice
          }, { "onUpdate:amount": updateAmount }), null, 16)) : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_PartialsTradingFormInputsBaseAmount, _mergeProps({
            amountStep: __props.amountStep,
            baseAvailableBalance: __props.baseAvailableBalance,
            isBaseAmount: __props.isBaseAmount,
            isBuy: __props.isBuy,
            isSpot: __props.isSpot,
            tradingTypeLimit: _unref(tradingTypeLimit),
            tradingTypeStopLimit: _unref(tradingTypeStopLimit),
            market: __props.market,
            orderbookOrders: _unref(orderbookOrders),
            baseAmountFieldName: _unref(TradeField).BaseAmount
          }, { "onUpdate:amount": updateAmount }), null, 16),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_PartialsTradingFormPercentageOptions, _mergeProps({ class: "mb-2" }, {
              baseAvailableBalance: __props.baseAvailableBalance,
              feeRate: __props.feeRate,
              isBuy: __props.isBuy,
              isSpot: __props.isSpot,
              market: __props.market,
              maxAmountOnOrderbook: __props.maxAmountOnOrderbook,
              maxReduceOnly: __props.maxReduceOnly,
              orderbookOrders: _unref(orderbookOrders),
              orderTypeReduceOnly: __props.orderTypeReduceOnly,
              position: __props.position,
              quoteAvailableBalance: __props.quoteAvailableBalance,
              percentageFieldName: _unref(TradeField).ProportionalPercentage
            }, { "onUpdate:amount": updateAmount }), null, 16),
            _createVNode(_component_PartialsTradingFormInputsQuoteAmount, _mergeProps({
              amountStep: __props.amountStep,
              fees: __props.fees,
              market: __props.market,
              quoteAvailableBalance: __props.quoteAvailableBalance,
              quoteAmountFieldName: _unref(TradeField).QuoteAmount
            }, { "onUpdate:amount": updateAmount }), null, 16)
          ])
        ])
      ], 64);
    };
  }
});
