"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-4" };
const _hoisted_2 = {
  key: 0,
  class: "text-xs font-semibold uppercase"
};
const _hoisted_3 = { class: "flex items-center justify-center" };
const _hoisted_4 = { class: "mr-2" };
import { getHubUrl } from "@/app/utils/helpers";
export default /* @__PURE__ */ _defineComponent({
  __name: "InsufficientGasInner",
  props: {
    hideTitle: Boolean
  },
  emits: ["close"],
  setup(__props, { emit }) {
    const faucetUrl = "https://inj.supply/";
    const hubUrl = `${getHubUrl()}/bridge`;
    function handleClose() {
      emit("close");
    }
    return (_ctx, _cache) => {
      const _component_NuxtLink = _resolveComponent("NuxtLink");
      const _component_i18n_t = _resolveComponent("i18n-t");
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      const _component_AppButton = _resolveComponent("AppButton");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        !__props.hideTitle ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("insufficientGas.insufficientGas")), 1)) : _createCommentVNode("", true),
        _createVNode(_component_i18n_t, {
          keypath: "insufficientGas.insufficientGasNoteDescription",
          tag: "p",
          class: "text-xs"
        }, {
          faucetLink: _withCtx(() => [
            _createVNode(_component_NuxtLink, {
              target: "_blank",
              class: "text-blue-500",
              to: faucetUrl,
              onClick: handleClose
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("insufficientGas.communityDrivenFaucet")), 1)
              ]),
              _: 1
            })
          ]),
          hubLink: _withCtx(() => [
            _createVNode(_component_NuxtLink, {
              target: "_blank",
              class: "text-blue-500",
              to: hubUrl,
              onClick: handleClose
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("insufficientGas.injectiveHub")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_NuxtLink, { to: faucetUrl }, {
          default: _withCtx(() => [
            _createVNode(_component_AppButton, {
              type: "button",
              class: "whitespace-nowrap w-full bg-blue-500 text-black",
              onClick: handleClose
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("insufficientGas.getFreeInj")), 1),
                  _createVNode(_component_BaseIcon, {
                    name: "external-link",
                    class: "w-3 h-3"
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]);
    };
  }
});
