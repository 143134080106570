import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex flex-start items-center gap-2 h-6" };
const _hoisted_2 = {
  key: 0,
  class: "w-3 h-3",
  src: "/Orderbook/default.svg"
};
const _hoisted_3 = {
  key: 1,
  class: "w-3 h-3",
  src: "/Orderbook/buys.svg"
};
const _hoisted_4 = {
  key: 2,
  class: "w-3 h-3",
  src: "/Orderbook/sells.svg"
};
import { OrderbookLayout } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "LayoutSelector",
  setup(__props) {
    const appStore = useAppStore();
    const FilterList = [
      OrderbookLayout.Default,
      OrderbookLayout.Buys,
      OrderbookLayout.Sells
    ];
    const orderbookLayoutType = computed({
      get: () => appStore.userState.orderbookLayout,
      set: (orderbookLayout) => {
        appStore.setUserState({
          ...appStore.userState,
          orderbookLayout
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(FilterList, (layoutType) => {
          return _createVNode(_component_AppSelectButton, {
            key: `orderbook-layout-type-${layoutType}`,
            modelValue: _unref(orderbookLayoutType),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(orderbookLayoutType) ? orderbookLayoutType.value = $event : null),
            value: layoutType
          }, {
            default: _withCtx(({ active }) => [
              _createElementVNode("span", {
                class: _normalizeClass(["w-6 h-6 flex justify-center items-center", [active ? "opacity-100" : "opacity-30 hover:opacity-100"]])
              }, [
                layoutType === _unref(OrderbookLayout).Default ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : layoutType === _unref(OrderbookLayout).Buys ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : layoutType === _unref(OrderbookLayout).Sells ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true)
              ], 2)
            ]),
            _: 2
          }, 1032, ["modelValue", "value"]);
        }), 64))
      ]);
    };
  }
});
