import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "mt-6 flex items-center justify-center gap-2" };
import { DEFAULT_PRICE_WARNING_DEVIATION } from "@/app/utils/constants";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "PriceDeviation",
  emits: ["order:confirmed"],
  setup(__props, { emit }) {
    const modalStore = useModalStore();
    const isModalOpen = computed(() => modalStore.modals[Modal.PriceDeviation]);
    function confirm() {
      emit("order:confirmed");
      close();
    }
    function close() {
      modalStore.closeModal(Modal.PriceDeviation);
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      const _component_AppModal = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        "data-cy": "price-deviation-modal",
        sm: "",
        "onModal:closed": close
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("trade.confirmOrderExecution")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("trade.high_execution_price_deviation_warning_note", {
              percentage: _unref(DEFAULT_PRICE_WARNING_DEVIATION)
            })), 1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppButton, {
                class: "bg-blue-500 text-blue-900",
                "data-cy": "confirm-order-modal-confirm-button",
                onClick: confirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.confirm")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppButton, {
                class: "text-red-500 bg-red-500 bg-opacity-10 font-semibold hover:text-white",
                "data-cy": "confirm-order-modal-confirm-button",
                onClick: close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
