import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Radio/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Radio/Group.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex-col p-4 gap-3 pointer-events-none hidden lg:flex lg:pointer-events-auto text-white" };
const _hoisted_2 = { class: "font-semibold" };
const _hoisted_3 = /* @__PURE__ */ _createElementVNode("img", {
  src: "/images/layout-left.svg",
  class: "pointer-events-none"
}, null, -1);
const _hoisted_4 = [
  _hoisted_3
];
const _hoisted_5 = /* @__PURE__ */ _createElementVNode("img", {
  src: "/images/layout-right.svg",
  class: "pointer-events-none"
}, null, -1);
const _hoisted_6 = [
  _hoisted_5
];
import { TradingLayout } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "LayoutPreferences",
  setup(__props) {
    const appStore = useAppStore();
    function handleChangeTradingLayout(tradingLayout) {
      appStore.setUserState({
        ...appStore.userState,
        tradingLayout
      });
    }
    return (_ctx, _cache) => {
      const _component_AppRadioButton = __nuxt_component_0;
      const _component_AppRadioGroup = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.tradingLayout")), 1),
        _createVNode(_component_AppRadioGroup, {
          class: "flex gap-8 items-start",
          value: _unref(appStore).userState.tradingLayout,
          onChange: handleChangeTradingLayout
        }, {
          options: _withCtx(({ value, setValue }) => [
            _createVNode(_component_AppRadioButton, {
              active: value === _unref(TradingLayout).Left,
              onClick: ($event) => setValue(_unref(TradingLayout).Left)
            }, {
              label: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.tradingLayoutOptions.left")), 1)
              ]),
              addon: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["border rounded-sm", {
                    "border-white": value === _unref(TradingLayout).Left,
                    "border-transparent": value !== _unref(TradingLayout).Left
                  }])
                }, _hoisted_4, 2)
              ]),
              _: 2
            }, 1032, ["active", "onClick"]),
            _createVNode(_component_AppRadioButton, {
              active: value === _unref(TradingLayout).Right,
              onClick: ($event) => setValue(_unref(TradingLayout).Right)
            }, {
              label: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.tradingLayoutOptions.right")), 1)
              ]),
              addon: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["border rounded-sm", {
                    "border-white": value === _unref(TradingLayout).Right,
                    "border-transparent": value !== _unref(TradingLayout).Right
                  }])
                }, _hoisted_6, 2)
              ]),
              _: 2
            }, 1032, ["active", "onClick"])
          ]),
          _: 1
        }, 8, ["value"])
      ]);
    };
  }
});
