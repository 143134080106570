"use strict";
import { defineComponent as _defineComponent } from "vue";
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import config from "@/app/trading-view/config";
import { widget as TradingViewWidget } from "@/assets/js/chart/charting_library.esm";
export default /* @__PURE__ */ _defineComponent({
  __name: "TradingView",
  props: {
    symbol: {
      required: true,
      type: String
    },
    interval: {
      required: true,
      type: String
    },
    datafeedEndpoint: {
      required: true,
      type: String
    }
  },
  emits: ["ready"],
  setup(__props, { emit }) {
    const props = __props;
    const containerId = `tv_chart_container-${window.crypto.getRandomValues(new Uint32Array(1))[0].toString()}`;
    const tradingView = ref(null);
    onMounted(() => {
      const widgetOptions = config({
        containerId,
        symbol: props.symbol,
        interval: props.interval,
        datafeedEndpoint: props.datafeedEndpoint
      });
      const tradingWidget = new TradingViewWidget(widgetOptions);
      tradingWidget.onChartReady(() => {
        tradingWidget.applyOverrides(widgetOptions.overrides);
        nextTick(() => {
          tradingView.view = tradingWidget;
          emit("ready");
        });
      });
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        id: containerId,
        ref_key: "tradingView",
        ref: tradingView,
        class: "tv_chart_container w-full h-full"
      }, null, 512);
    };
  }
});
