import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TextInfo.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "font-mono flex items-center",
  "data-cy": "trading-page-details-fee-percentage-text-content"
};
const _hoisted_2 = {
  class: "font-mono flex items-center",
  "data-cy": "trading-page-details-fee-percentage-text-content"
};
const _hoisted_3 = {
  class: "font-mono flex items-center",
  "data-cy": "trading-page-details-fee-percentage-text-content"
};
import { getExactDecimalsFromNumber } from "@injectivelabs/sdk-ts";
import { TradeExecutionType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "MakerTakerFeeRate",
  props: {
    postOnly: Boolean,
    tradingType: {
      type: String,
      required: true
    },
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const { makerFeeRate, takerFeeRate } = useTradeFee(computed(() => props.market));
    const makerFeeRateToFormat = computed(() => {
      const number = makerFeeRate.value.times(100);
      return number.toFormat(getExactDecimalsFromNumber(number.toNumber()));
    });
    const takerFeeRateToFormat = computed(() => {
      const number = takerFeeRate.value.times(100);
      return number.toFormat(getExactDecimalsFromNumber(number.toNumber()));
    });
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_CommonTextInfo = __nuxt_component_1;
      return [_unref(TradeExecutionType).Market, _unref(TradeExecutionType).StopMarket].includes(
        __props.tradingType
      ) ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
        key: 0,
        title: _ctx.$t("trade.taker_rate"),
        class: "mt-2"
      }, {
        context: _withCtx(() => [
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.taker_rate_note")
          }, null, 8, ["tooltip"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(`${_unref(takerFeeRateToFormat)}%`), 1)
          ])
        ]),
        _: 1
      }, 8, ["title"])) : __props.postOnly ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
        key: 1,
        title: _ctx.$t("trade.maker_rate"),
        class: "mt-2"
      }, {
        context: _withCtx(() => [
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.maker_rate_note")
          }, null, 8, ["tooltip"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(`${_unref(makerFeeRateToFormat)}%`), 1)
          ])
        ]),
        _: 1
      }, 8, ["title"])) : (_openBlock(), _createBlock(_component_CommonTextInfo, {
        key: 2,
        title: _ctx.$t("trade.maker_taker_rate"),
        class: "mt-2"
      }, {
        context: _withCtx(() => [
          _createVNode(_component_CommonInfoTooltip, {
            class: "ml-2",
            tooltip: _ctx.$t("trade.maker_taker_rate_note")
          }, null, 8, ["tooltip"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(`${_unref(makerFeeRateToFormat)}%/${_unref(takerFeeRateToFormat)}%`), 1)
          ])
        ]),
        _: 1
      }, 8, ["title"]));
    };
  }
});
