import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col items-end font-mono"
};
const _hoisted_3 = {
  key: 0,
  class: "mt-1 text-xs"
};
import { BigNumberInBase, Status, StatusType } from "@injectivelabs/utils";
import { MarketType, ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { Change } from "@/types";
import { metaTags } from "@/nuxt-config/meta";
export default /* @__PURE__ */ _defineComponent({
  __name: "LastTradedPriceAndChange",
  props: {
    lg: Boolean,
    isStatsBar: Boolean,
    isCurrentMarket: Boolean,
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const { t } = useLang();
    const status = reactive(new Status(StatusType.Loading));
    const { lastTradedPrice: spotLastTradedPrice } = useSpotLastPrice(
      computed(() => props.market)
    );
    const { lastTradedPrice: derivativeLastTradedPrice } = useDerivativeLastPrice(
      computed(() => props.market)
    );
    const isSpot = computed(() => props.market.type === MarketType.Spot);
    const lastTradedPrice = computed(() => {
      if (props.isCurrentMarket) {
        return isSpot.value ? spotLastTradedPrice.value : derivativeLastTradedPrice.value;
      }
      return new BigNumberInBase(
        props.summary.lastPrice || props.summary.price || 0
      );
    });
    const percentageChangeStatus = computed(() => {
      if (change.value.eq(0)) {
        return Change.NoChange;
      }
      return change.value.gt(0) ? Change.Increase : Change.Decrease;
    });
    const { valueToString: lastTradedPriceToFormat } = useBigNumberFormatter(
      computed(() => lastTradedPrice.value),
      {
        decimalPlaces: props.market.priceDecimals,
        displayAbsoluteDecimalPlace: true
      }
    );
    const { valueToFixed: changeToFormat, valueToBigNumber: change } = useBigNumberFormatter(
      computed(() => {
        if (!props.summary || !props.summary.change) {
          return ZERO_IN_BASE;
        }
        return props.summary.change;
      })
    );
    watch(lastTradedPriceToFormat, (newPrice) => {
      const marketTypePrefix = [
        MarketType.Derivative,
        MarketType.Futures,
        MarketType.Perpetual
      ].includes(props.market.type) ? `| ${t("trade.futures")}` : "";
      if (props.isStatsBar) {
        document.title = `${newPrice} - ${props.market.ticker} ${marketTypePrefix} | ${metaTags.title}`;
      }
    });
    onBeforeUnmount(() => document.title = metaTags.title);
    useTimeoutFn(() => status.setIdle(), 3 * 1e3);
    return (_ctx, _cache) => {
      const _component_AppSpinner = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", null, [
        __props.isStatsBar && _unref(status).isLoading() && (_unref(lastTradedPrice).isNaN() || _unref(lastTradedPrice).lte(0)) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppSpinner, { xs: "" })
        ])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center tracking-wide leading-none", { "text-xs": !__props.lg }])
          }, [
            [_unref(Change).Increase, _unref(Change).Decrease].includes(_unref(percentageChangeStatus)) ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: "arrow",
              class: _normalizeClass(["transform w-3 h-3 mr-1", {
                "text-green-500 rotate-90": _unref(percentageChangeStatus) === _unref(Change).Increase,
                "text-red-500 -rotate-90": _unref(percentageChangeStatus) === _unref(Change).Decrease
              }])
            }, null, 8, ["class"])) : _createCommentVNode("", true),
            _createElementVNode("span", {
              "data-cy": "markets-last-traded-price-table-data",
              class: _normalizeClass({
                "text-green-500": _unref(percentageChangeStatus) === _unref(Change).Increase,
                "text-white": _unref(percentageChangeStatus) === _unref(Change).NoChange,
                "text-red-500": _unref(percentageChangeStatus) === _unref(Change).Decrease
              })
            }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)
          ], 2),
          !_unref(change).isNaN() ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass({
                "text-green-500": _unref(percentageChangeStatus) === _unref(Change).Increase,
                "text-white": _unref(percentageChangeStatus) === _unref(Change).NoChange,
                "text-red-500": _unref(percentageChangeStatus) === _unref(Change).Decrease
              }),
              "data-cy": "markets-change_24h-table-data"
            }, _toDisplayString(_unref(changeToFormat)) + "% ", 3)
          ])) : _createCommentVNode("", true)
        ]))
      ]);
    };
  }
});
