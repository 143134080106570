import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Search.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between" };
const _hoisted_2 = { class: "flex items-center flex-wrap gap-5" };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 2 };
const _hoisted_5 = { key: 3 };
const _hoisted_6 = { class: "w-full mt-4" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketsFilter",
  props: {
    search: {
      type: String,
      required: true
    },
    activeType: {
      type: String,
      required: true
    }
  },
  emits: ["update:activeType", "update:search"],
  setup(__props, { emit }) {
    const props = __props;
    const filterList = [
      MarketType.Favorite,
      "",
      MarketType.Spot,
      MarketType.Derivative
    ];
    const activeTypeValue = computed({
      get: () => props.activeType,
      set: (type) => {
        emit("update:activeType", type);
      }
    });
    const searchValue = computed({
      get: () => props.search,
      set: (value) => {
        emit("update:search", value);
      }
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppSelectButton = __nuxt_component_1;
      const _component_AppSearch = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(filterList, (marketType) => {
              return _createVNode(_component_AppSelectButton, {
                key: `market-type-${marketType}`,
                modelValue: _unref(activeTypeValue),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeTypeValue) ? activeTypeValue.value = $event : null),
                value: marketType
              }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["tracking-widest text-xs uppercase cursor-pointer select-none border-none p-0", [
                      active ? "font-bold text-gray-200" : "text-gray-500 hover:text-gray-200"
                    ]])
                  }, [
                    marketType === _unref(MarketType).Favorite ? (_openBlock(), _createBlock(_component_BaseIcon, {
                      key: 0,
                      name: "star",
                      class: "min-w-4 h-4 w-4"
                    })) : marketType === _unref(MarketType).Derivative ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.futures")), 1)) : marketType === _unref(MarketType).Spot ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.spots")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.all")), 1))
                  ], 2)
                ]),
                _: 2
              }, 1032, ["modelValue", "value"]);
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AppSearch, {
            modelValue: _unref(searchValue),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(searchValue) ? searchValue.value = $event : null),
            class: "w-full text-xs py-2",
            "data-cy": "markets-search-input",
            placeholder: _ctx.$t("trade.search_markets")
          }, null, 8, ["modelValue", "placeholder"])
        ])
      ]);
    };
  }
});
