"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex justify-between items-center flex-wrap lg:flex-nowrap gap-4 h-full" };
const _hoisted_2 = { class: "flex mt-2 justify-between items-center w-full lg:w-auto lg:mt-0 gap-6" };
const _hoisted_3 = { class: "leading-none select-none cursor-pointer" };
const _hoisted_4 = { class: "text-gray-100 font-semibold text-sm flex items-center" };
const _hoisted_5 = {
  "data-cy": "trading-page-ticker-name-text-content",
  class: "whitespace-nowrap overflow-ellipsis overflow-hidden"
};
const _hoisted_6 = { class: "text-gray-500 text-xs" };
const _hoisted_7 = /* @__PURE__ */ _createElementVNode("div", { class: "w-px h-8 border-r hidden lg:block" }, null, -1);
const _hoisted_8 = { class: "ml-auto hidden lg:block" };
const _hoisted_9 = {
  id: "layout-preferences-button",
  class: "w-6 h-6 cursor-pointer group flex justify-center items-center"
};
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    expanded: Boolean,
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    }
  },
  emits: ["marketsList:toggle"],
  setup(__props, { emit }) {
    function handleTokenClick() {
      emit("marketsList:toggle");
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = _resolveComponent("CommonTokenIcon");
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      const _component_PartialsCommonMarketAirdrop = _resolveComponent("PartialsCommonMarketAirdrop");
      const _component_PartialsTradingMarketStatsPartialsLastTradedPriceAndChange = _resolveComponent("PartialsTradingMarketStatsPartialsLastTradedPriceAndChange");
      const _component_PartialsTradingMarketStatsPartials = _resolveComponent("PartialsTradingMarketStatsPartials");
      const _component_PartialsTradingMarketLayoutPreferences = _resolveComponent("PartialsTradingMarketLayoutPreferences");
      const _component_BaseHoverMenu = _resolveComponent("BaseHoverMenu");
      const _component_CommonCard = _resolveComponent("CommonCard");
      return _openBlock(), _createBlock(_component_CommonCard, {
        "no-top-border-radius": "",
        "no-padding": "",
        class: "h-full px-4",
        "data-cy": "trading-page-market-info-component"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "flex items-center gap-4",
                onClick: handleTokenClick
              }, [
                __props.market.baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                  key: 0,
                  token: __props.market.baseToken
                }, null, 8, ["token"])) : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(__props.market.ticker), 1),
                    _createVNode(_component_BaseIcon, {
                      name: "chevron",
                      class: _normalizeClass(["w-auto h-3 text-gray-500 ml-2 transform transition ease-in-out duration-300", [__props.expanded ? "rotate-90" : "-rotate-90"]])
                    }, null, 8, ["class"])
                  ]),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(__props.market.baseToken.name), 1)
                ]),
                _createVNode(_component_PartialsCommonMarketAirdrop, { market: __props.market }, null, 8, ["market"])
              ]),
              _hoisted_7,
              __props.summary ? (_openBlock(), _createBlock(_component_PartialsTradingMarketStatsPartialsLastTradedPriceAndChange, {
                key: 0,
                market: __props.market,
                summary: __props.summary,
                lg: "",
                "is-current-market": "",
                "is-stats-bar": ""
              }, null, 8, ["market", "summary"])) : _createCommentVNode("", true)
            ]),
            __props.summary ? (_openBlock(), _createBlock(_component_PartialsTradingMarketStatsPartials, {
              key: 0,
              market: __props.market,
              summary: __props.summary,
              class: "w-full lg:w-auto pb-4 lg:pb-0"
            }, null, 8, ["market", "summary"])) : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_BaseHoverMenu, { "popper-class": "rounded-lg flex flex-col flex-wrap text-xs absolute w-80 bg-gray-750 shadow-dropdown z-40" }, {
                default: _withCtx(() => [
                  _createElementVNode("button", _hoisted_9, [
                    _createVNode(_component_BaseIcon, {
                      name: "sliders",
                      class: "text-gray-450 group-hover:text-white w-4 h-4"
                    })
                  ])
                ]),
                content: _withCtx(() => [
                  _createVNode(_component_PartialsTradingMarketLayoutPreferences)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      });
    };
  }
});
