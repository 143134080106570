import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Numeric.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = /* @__PURE__ */ _createElementVNode("span", null, "\u2248", -1);
import { formatAmountToAllowableAmount } from "@injectivelabs/sdk-ts";
import { TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "QuoteAmount",
  props: {
    amountStep: {
      type: String,
      required: true
    },
    fees: {
      type: Object,
      default: void 0
    },
    quoteAmountFieldName: {
      type: String,
      required: true
    },
    quoteAvailableBalance: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    }
  },
  emits: ["update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const formValues = useFormValues();
    const { hasTriggerPrice, tradingTypeStopMarket } = useDerivativeFormFormatter(formValues);
    const { value: quoteAmount } = useStringField({
      name: props.quoteAmountFieldName,
      rule: "",
      dynamicRule: computed(() => {
        const rules = [];
        const formIsStopMarketAndHasNoTriggerPrice = tradingTypeStopMarket.value && !hasTriggerPrice.value;
        if (!formIsStopMarketAndHasNoTriggerPrice) {
          rules.push(`integer:${TradeField.QuoteAmount}`);
        }
        return rules.join("|");
      })
    });
    function onQuoteAmountChange(quoteAmount2) {
      formValues.value[TradeField.ProportionalPercentage] = 0;
      emit("update:amount", { amount: quoteAmount2 || "0", isBaseAmount: false });
    }
    function onQuoteAmountBlur() {
      if (props.market.quantityTensMultiplier < 1) {
        return;
      }
      formValues.value[TradeField.BaseAmount] = formatAmountToAllowableAmount(
        formValues.value[TradeField.BaseAmount],
        props.market.quantityTensMultiplier
      );
      emit("update:amount", {
        isBaseAmount: true
      });
    }
    return (_ctx, _cache) => {
      const _component_AppInputNumeric = __nuxt_component_0;
      return _openBlock(), _createBlock(_component_AppInputNumeric, {
        modelValue: _unref(quoteAmount),
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event) => _isRef(quoteAmount) ? quoteAmount.value = $event : null),
          onQuoteAmountChange
        ],
        "max-decimals": __props.market.priceDecimals,
        placeholder: __props.amountStep,
        step: __props.amountStep,
        min: "0",
        onBlur: onQuoteAmountBlur
      }, {
        prefix: _withCtx(() => [
          _hoisted_1
        ]),
        addon: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(__props.market.quoteToken.symbol), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "max-decimals", "placeholder", "step"]);
    };
  }
});
