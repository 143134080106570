import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/MarketInfo.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { format } from "date-fns";
import { DMM_TIME_STAMP_FORMAT } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Settlement",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const expiredAt = computed(
      () => format(
        props.market.settlementTimestamp * 1e3,
        DMM_TIME_STAMP_FORMAT
      )
    );
    return (_ctx, _cache) => {
      const _component_CommonMarketInfo = __nuxt_component_0;
      return _openBlock(), _createBlock(_component_CommonMarketInfo, {
        title: _ctx.$t("trade.binaryOptions.settlement"),
        tooltip: _ctx.$t("trade.binaryOptions.settlement_tooltip")
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(expiredAt)), 1)
        ]),
        _: 1
      }, 8, ["title", "tooltip"]);
    };
  }
});
