import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Loading.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Market/Chart/TradingView.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/client-only";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "bg-gray-1000 lg:rounded-l-xl lg:shadow-sm h-full overflow-hidden relative" };
const _hoisted_2 = {
  ref: "trading-view-wrap",
  class: "orderbook-h lg:h-full lg:min-h-full h-full w-full relative flex"
};
import { Status, StatusType } from "@injectivelabs/utils";
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { getChronosDatafeedEndpoint } from "@/app/utils/helpers";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isSpot = props.market.type === MarketType.Spot;
    const interval = "120";
    const status = reactive(new Status(StatusType.Loading));
    const symbol = computed(() => {
      if (!isSpot) {
        return props.market.ticker;
      }
      const spotTicker = `${props.market.baseDenom}/${props.market.quoteDenom}`;
      return spotTicker.replaceAll("ibc/", "ibc@");
    });
    const datafeedEndpoint = computed(
      () => getChronosDatafeedEndpoint(
        props.market.type === MarketType.Derivative ? "derivative" : "spot"
      )
    );
    function onReady() {
      status.setIdle();
    }
    return (_ctx, _cache) => {
      const _component_AppLoading = __nuxt_component_0;
      const _component_PartialsTradingView = __nuxt_component_1;
      const _component_client_only = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _unref(status).isLoading() ? (_openBlock(), _createBlock(_component_AppLoading, { key: 0 })) : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_client_only, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_PartialsTradingView, {
                ref: "trading-view",
                interval,
                symbol: _unref(symbol),
                "datafeed-endpoint": _unref(datafeedEndpoint),
                onReady
              }, null, 8, ["symbol", "datafeed-endpoint"]), [
                [_vShow, _unref(status).isNotLoading()]
              ])
            ]),
            _: 1
          })
        ], 512)
      ]);
    };
  }
});
