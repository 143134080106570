import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/MarketInfo.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Market/Stats/Partials/NextFunding.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Market/Stats/Partials/Settlement.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "grid grid-cols-2 md:grid-cols-3 gap-2.5 lg:gap-0 lg:flex overflow-hidden text-xs" };
const _hoisted_2 = {
  key: 0,
  class: "lg:text-right font-mono block",
  "data-cy": "market-info-mark-price-span"
};
const _hoisted_3 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_4 = {
  key: 0,
  class: "lg:text-right font-mono block",
  "data-cy": "market-info-volume-24h-span"
};
const _hoisted_5 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_6 = { class: "lg:text-right font-mono block" };
const _hoisted_7 = {
  key: 0,
  "data-cy": "market-info-high-price-24h-span"
};
const _hoisted_8 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_9 = { class: "lg:text-right font-mono block" };
const _hoisted_10 = {
  key: 0,
  "data-cy": "market-info-low-price-24h-span"
};
const _hoisted_11 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_12 = {
  key: 0,
  class: "lg:text-right font-mono block"
};
const _hoisted_13 = {
  key: 1,
  class: "lg:text-right font-mono block"
};
const _hoisted_14 = {
  key: 0,
  class: "lg:text-right font-mono block"
};
const _hoisted_15 = {
  key: 1,
  class: "lg:text-right font-mono block"
};
const _hoisted_16 = {
  key: 0,
  class: "lg:text-right font-mono block"
};
const _hoisted_17 = {
  key: 1,
  class: "lg:text-right font-mono block"
};
import { BigNumberInBase } from "@injectivelabs/utils";
import { fromUnixTime, formatDistance, format } from "date-fns";
import {
  MarketType,
  ZERO_IN_BASE,
  BIG_NUMBER_ROUND_DOWN_MODE
} from "@injectivelabs/sdk-ui-ts";
import { stableCoinDenoms } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const userTimezone = format(new Date(), "OOOO");
    const now = ref(Date.now() / 1e3);
    const { markPrice } = useDerivativeLastPrice(computed(() => props.market));
    const {
      valueToString: markPriceToFormat,
      valueToBigNumber: markPriceToBigNumber
    } = useBigNumberFormatter(
      computed(() => {
        if (props.market.type === MarketType.Spot) {
          return ZERO_IN_BASE;
        }
        return markPrice.value;
      }),
      {
        decimalPlaces: props.market.priceDecimals,
        displayAbsoluteDecimalPlace: true
      }
    );
    const { valueToString: highToFormat, valueToBigNumber: high } = useBigNumberFormatter(
      computed(() => {
        if (!props.summary) {
          return ZERO_IN_BASE;
        }
        return new BigNumberInBase(props.summary.high);
      }),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const { valueToBigNumber: tWapEst } = useBigNumberFormatter(
      computed(() => {
        if (props.market.type === MarketType.Spot) {
          return ZERO_IN_BASE;
        }
        if (props.market.subType === MarketType.BinaryOptions) {
          return ZERO_IN_BASE;
        }
        if (props.market.subType === MarketType.Futures) {
          return ZERO_IN_BASE;
        }
        const derivativeMarket = props.market;
        if (!derivativeMarket.perpetualMarketFunding || !derivativeMarket.isPerpetual) {
          return ZERO_IN_BASE;
        }
        const currentUnixTime = Date.now() / 1e3;
        const divisor = new BigNumberInBase(currentUnixTime).mod(3600).times(24);
        if (divisor.lte(0)) {
          return ZERO_IN_BASE;
        }
        return new BigNumberInBase(
          derivativeMarket.perpetualMarketFunding.cumulativePrice
        ).dividedBy(divisor);
      })
    );
    const { valueToBigNumber: fundingRate } = useBigNumberFormatter(
      computed(() => {
        if (props.market.type === MarketType.Spot) {
          return ZERO_IN_BASE;
        }
        if (props.market.subType === MarketType.BinaryOptions) {
          return ZERO_IN_BASE;
        }
        if (props.market.subType === MarketType.Futures) {
          return ZERO_IN_BASE;
        }
        const derivativeMarket = props.market;
        if (!derivativeMarket.perpetualMarketFunding || !derivativeMarket.isPerpetual || !derivativeMarket.perpetualMarketInfo) {
          return ZERO_IN_BASE;
        }
        const hourlyFundingRateCap = new BigNumberInBase(
          derivativeMarket.perpetualMarketInfo.hourlyFundingRateCap
        );
        const estFundingRate = new BigNumberInBase(
          derivativeMarket.perpetualMarketInfo.hourlyInterestRate
        ).plus(tWapEst.value);
        if (estFundingRate.gt(hourlyFundingRateCap)) {
          return new BigNumberInBase(hourlyFundingRateCap).multipliedBy(100);
        }
        if (estFundingRate.lt(hourlyFundingRateCap.times(-1))) {
          return new BigNumberInBase(hourlyFundingRateCap).times(-1).multipliedBy(100);
        }
        return new BigNumberInBase(estFundingRate).multipliedBy(100);
      })
    );
    const { valueToString: lowToFormat, valueToBigNumber: low } = useBigNumberFormatter(
      computed(() => {
        if (!props.summary) {
          return ZERO_IN_BASE;
        }
        return new BigNumberInBase(props.summary.low);
      }),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const { valueToString: volumeToFormat, valueToBigNumber: volume } = useBigNumberFormatter(
      computed(() => {
        if (!props.summary) {
          return ZERO_IN_BASE;
        }
        return new BigNumberInBase(props.summary.volume);
      }),
      {
        decimalPlaces: stableCoinDenoms.includes(props.market.quoteToken.symbol) ? 0 : props.market.priceDecimals
      }
    );
    const expiryAt = computed(() => {
      if (props.market.type === MarketType.Spot) {
        return "";
      }
      if (props.market.subType === MarketType.BinaryOptions) {
        return "";
      }
      if (props.market.subType === MarketType.Perpetual) {
        return "";
      }
      const derivativeMarket = props.market;
      const expiryFuturesMarketInfo = derivativeMarket.expiryFuturesMarketInfo;
      if (!expiryFuturesMarketInfo) {
        return "";
      }
      if (!expiryFuturesMarketInfo.expirationTimestamp) {
        return "";
      }
      return format(
        fromUnixTime(expiryFuturesMarketInfo.expirationTimestamp),
        "dd LLL yyyy, HH:mm:ss"
      );
    });
    const isExpired = computed(() => {
      if (props.market.type === MarketType.Spot) {
        return true;
      }
      if (props.market.subType === MarketType.BinaryOptions) {
        return true;
      }
      if (props.market.subType === MarketType.Perpetual) {
        return true;
      }
      const derivativeMarket = props.market;
      const expiryFuturesMarketInfo = derivativeMarket.expiryFuturesMarketInfo;
      if (!expiryFuturesMarketInfo) {
        return true;
      }
      if (!expiryFuturesMarketInfo.expirationTimestamp) {
        return true;
      }
      return expiryFuturesMarketInfo.expirationTimestamp <= now.value;
    });
    const timeToExpiry = computed(() => {
      if (props.market.type === MarketType.Spot) {
        return "";
      }
      if (props.market.subType === MarketType.BinaryOptions) {
        return "";
      }
      if (props.market.subType === MarketType.Perpetual) {
        return "";
      }
      const derivativeMarket = props.market;
      const expiryFuturesMarketInfo = derivativeMarket.expiryFuturesMarketInfo;
      if (!expiryFuturesMarketInfo) {
        return "";
      }
      if (!expiryFuturesMarketInfo.expirationTimestamp) {
        return "";
      }
      const nowInMilliseconds = new BigNumberInBase(now.value).times(1e3).toNumber();
      const expirationTimestampInMilliseconds = new BigNumberInBase(
        expiryFuturesMarketInfo.expirationTimestamp
      ).times(1e3).toNumber();
      return formatDistance(nowInMilliseconds, expirationTimestampInMilliseconds);
    });
    watch(isExpired, (hasExpired) => {
      if (props.market.subType === MarketType.Futures && hasExpired) {
        window.location.reload();
      }
    });
    useIntervalFn(() => {
      now.value = Date.now() / 1e3;
    }, 5e3);
    return (_ctx, _cache) => {
      const _component_CommonMarketInfo = __nuxt_component_0;
      const _component_PartialsTradingMarketStatsPartialsNextFunding = __nuxt_component_1;
      const _component_PartialsTradingMarketStatsPartialsSettlement = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
          __props.market.type === _unref(MarketType).Derivative ? (_openBlock(), _createBlock(_component_CommonMarketInfo, {
            key: 0,
            title: _ctx.$t("trade.mark_price"),
            tooltip: _ctx.$t("trade.mark_price_tooltip")
          }, {
            default: _withCtx(() => [
              !_unref(markPriceToBigNumber).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(markPriceToFormat)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, "\u2014"))
            ]),
            _: 1
          }, 8, ["title", "tooltip"])) : _createCommentVNode("", true),
          _createVNode(_component_CommonMarketInfo, {
            title: _ctx.$t("trade.volume_asset", { asset: __props.market.quoteToken.symbol }),
            tooltip: _ctx.$t("trade.market_volume_24h_tooltip")
          }, {
            default: _withCtx(() => [
              _unref(volume).gt(0) && !_unref(volume).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(volumeToFormat)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, "\u2014"))
            ]),
            _: 1
          }, 8, ["title", "tooltip"]),
          _createVNode(_component_CommonMarketInfo, {
            title: _ctx.$t("trade.high")
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, [
                _unref(high).gt(0) && !_unref(high).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(highToFormat)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, "\u2014"))
              ])
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_CommonMarketInfo, {
            title: _ctx.$t("trade.low")
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, [
                _unref(low).gt(0) && !_unref(low).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(lowToFormat)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_11, "\u2014"))
              ])
            ]),
            _: 1
          }, 8, ["title"]),
          __props.market.type === _unref(MarketType).Derivative && __props.market.subType === _unref(MarketType).Perpetual ? (_openBlock(), _createBlock(_component_CommonMarketInfo, {
            key: 1,
            title: _ctx.$t("trade.est_funding_rate"),
            tooltip: _ctx.$t("trade.funding_rate_tooltip")
          }, {
            default: _withCtx(() => [
              !_unref(fundingRate).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                _createElementVNode("span", {
                  class: _normalizeClass({
                    "text-green-500": _unref(fundingRate).gte(0),
                    "text-red-500": _unref(fundingRate).lt(0)
                  }),
                  "data-cy": "market-info-funding-rate-span"
                }, _toDisplayString((_unref(fundingRate).gt(0) ? "+" : "") + _unref(fundingRate).toFormat(5, _unref(BIG_NUMBER_ROUND_DOWN_MODE))) + "% ", 3)
              ])) : (_openBlock(), _createElementBlock("span", _hoisted_13, "\u2014"))
            ]),
            _: 1
          }, 8, ["title", "tooltip"])) : _createCommentVNode("", true),
          __props.market.subType === _unref(MarketType).Perpetual ? (_openBlock(), _createBlock(_component_PartialsTradingMarketStatsPartialsNextFunding, {
            key: 2,
            market: __props.market
          }, null, 8, ["market"])) : _createCommentVNode("", true),
          __props.market.subType === _unref(MarketType).BinaryOptions ? (_openBlock(), _createBlock(_component_PartialsTradingMarketStatsPartialsSettlement, {
            key: 3,
            market: __props.market
          }, null, 8, ["market"])) : _createCommentVNode("", true),
          __props.market.subType === _unref(MarketType).Futures && _unref(timeToExpiry) ? (_openBlock(), _createBlock(_component_CommonMarketInfo, {
            key: 4,
            title: _ctx.$t("trade.time_to_expiry")
          }, {
            default: _withCtx(() => [
              !_unref(isExpired) ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(timeToExpiry)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_15, "\u2014"))
            ]),
            _: 1
          }, 8, ["title"])) : _createCommentVNode("", true),
          __props.market.subType === _unref(MarketType).Futures && _unref(expiryAt) ? (_openBlock(), _createBlock(_component_CommonMarketInfo, {
            key: 5,
            title: _ctx.$t("trade.expiry_time_with_timezone", { timezone: _unref(userTimezone) })
          }, {
            default: _withCtx(() => [
              !_unref(isExpired) ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(expiryAt)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_17, "\u2014"))
            ]),
            _: 1
          }, 8, ["title"])) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});
