"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = {
  class: "w-1/3 text-xs px-2 text-right font-mono",
  "data-cy": "trades-table-quantity-span"
};
const _hoisted_3 = {
  class: "w-1/3 text-xs px-2 text-gray-500 text-right font-mono",
  "data-cy": "trades-table-time-span"
};
import { BigNumberInWei, BigNumberInBase } from "@injectivelabs/utils";
import { format } from "date-fns";
import { TradeDirection } from "@injectivelabs/ts-types";
import {
  MarketType
} from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "Item",
  props: {
    market: {
      type: Object,
      required: true
    },
    trade: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const isSpot = props.market.type === MarketType.Spot;
    const price = computed(
      () => isSpot ? new BigNumberInBase(
        new BigNumberInBase(props.trade.price).toWei(
          props.market.baseToken.decimals - props.market.quoteToken.decimals
        )
      ) : new BigNumberInWei(
        props.trade.executionPrice
      ).toBase(props.market.quoteToken.decimals)
    );
    const quantity = computed(
      () => isSpot ? new BigNumberInWei(props.trade.quantity).toBase(
        props.market.baseToken.decimals
      ) : new BigNumberInBase(props.trade.executionQuantity)
    );
    const { valueToFixed: priceToFormat } = useBigNumberFormatter(price, {
      decimalPlaces: props.market.priceDecimals
    });
    const { valueToFixed: quantityToFormat } = useBigNumberFormatter(quantity, {
      decimalPlaces: props.market.quantityDecimals
    });
    const time = computed(
      () => props.trade.executedAt ? format(props.trade.executedAt, "HH:mm:ss") : ""
    );
    return (_ctx, _cache) => {
      return __props.market ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        "data-cy": `trades-table-${__props.trade.tradeDirection === _unref(TradeDirection).Buy ? "buy" : "sell"}-row`,
        class: "flex h-6 items-center last:mb-0 first:mt-0 relative"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["w-1/3 text-xs px-2 text-right font-mono", {
            "text-green-500": __props.trade.tradeDirection === _unref(TradeDirection).Buy,
            "text-red-500": __props.trade.tradeDirection === _unref(TradeDirection).Sell
          }]),
          "data-cy": "trades-table-price-span"
        }, _toDisplayString(_unref(priceToFormat)), 3),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(quantityToFormat)), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(time)), 1)
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
