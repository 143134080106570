import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Sidebar/MarketsTable.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Card/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { BigNumberInBase } from "@injectivelabs/utils";
import { QUOTE_DENOMS_GECKO_IDS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const tokenStore = useTokenStore();
    const { $onError } = useNuxtApp();
    const marketsWithSummaryAndVolumeInUsd = computed(
      () => [...derivativeStore.marketsWithSummary, ...spotStore.marketsWithSummary].map(
        ({ market, summary }) => {
          const quoteTokenUsdPrice = new BigNumberInBase(
            tokenStore.tokenUsdPrice(market.quoteToken.coinGeckoId)
          );
          return {
            market,
            summary,
            volumeInUsd: quoteTokenUsdPrice.multipliedBy((summary == null ? void 0 : summary.volume) || "0")
          };
        }
      )
    );
    onMounted(() => {
      pollMarkets();
    });
    function pollMarkets() {
      Promise.all([
        tokenStore.fetchTokenUsdPriceMap(QUOTE_DENOMS_GECKO_IDS),
        derivativeStore.fetchMarketsSummary(),
        spotStore.fetchMarketsSummary()
      ]).catch($onError);
    }
    useIntervalFn(pollMarkets, 15 * 1e3, { immediate: true });
    return (_ctx, _cache) => {
      const _component_PartialsTradingSidebarMarketsTable = __nuxt_component_0;
      const _component_AppHocLoading = __nuxt_component_1;
      const _component_CommonCard = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_CommonCard, {
        class: "col-span-6 lg:col-span-3 4xl:col-span-3 h-screen-excluding-header-and-market-info pointer-events-auto overflow-y-auto",
        md: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppHocLoading, {
            "loader-class": "relative",
            "show-loading": _unref(marketsWithSummaryAndVolumeInUsd).length === 0
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PartialsTradingSidebarMarketsTable, {
                market: __props.market,
                markets: _unref(marketsWithSummaryAndVolumeInUsd)
              }, null, 8, ["market", "markets"])
            ]),
            _: 1
          }, 8, ["show-loading"])
        ]),
        _: 1
      });
    };
  }
});
