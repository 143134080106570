import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Form/Inputs/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Form/InputError.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Derivatives/Trading/OrderLeverage.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Form/AdvancedSettings.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import {
  DerivativeOrderSide,
  MarketType,
  SpotOrderSide,
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import {
  BusEvents,
  TradeExecutionType,
  TradeField
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "OrderInputs",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    isBaseAmount: Boolean,
    showReduceOnly: Boolean,
    orderTypeReduceOnly: Boolean,
    availableBalanceError: Boolean,
    markPriceThresholdError: Boolean,
    initialMinMarginRequirementError: Boolean,
    amountStep: {
      type: String,
      required: true
    },
    baseAvailableBalance: {
      type: Object,
      default: void 0
    },
    executionPrice: {
      type: Object,
      required: true
    },
    feeRate: {
      type: Object,
      default: ZERO_IN_BASE
    },
    fees: {
      type: Object,
      default: void 0
    },
    lastTradedPrice: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    maxAmountOnOrderbook: {
      type: Object,
      required: true
    },
    maxReduceOnly: {
      type: Object,
      default: void 0
    },
    position: {
      type: Object,
      default: void 0
    },
    priceStep: {
      type: String,
      required: true
    },
    quoteAvailableBalance: {
      type: Object,
      required: true
    },
    worstPriceWithSlippage: {
      type: Object,
      required: true
    }
  },
  emits: ["update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const formValues = useFormValues();
    const isSpot = props.market.type === MarketType.Spot;
    onMounted(() => {
      useEventBus(BusEvents.OrderbookPriceClick).on(
        onOrderbookPriceClick
      );
      useEventBus(BusEvents.OrderbookSizeClick).on(
        onOrderbookSizeClick
      );
      useEventBus(BusEvents.OrderbookNotionalClick).on(
        onOrderbookNotionalClick
      );
    });
    function updateAmount({
      amount,
      isBaseAmount
    }) {
      emit("update:amount", { amount, isBaseAmount });
    }
    function updateOrderType(isBuy) {
      const orderTypeBuy = isSpot ? SpotOrderSide.Buy : DerivativeOrderSide.Buy;
      const orderTypeSell = isSpot ? SpotOrderSide.Sell : DerivativeOrderSide.Sell;
      formValues.value[TradeField.OrderType] = isBuy ? orderTypeSell : orderTypeBuy;
    }
    function onOrderbookNotionalClick(notionalAndType) {
      updateOrderType(notionalAndType.isBuy);
      formValues.value[TradeField.TradingType] = TradeExecutionType.Market;
      formValues.value[TradeField.QuoteAmount] = notionalAndType.total;
      updateAmount({ isBaseAmount: false });
    }
    function onOrderbookSizeClick(quantityAndOrderType) {
      updateOrderType(quantityAndOrderType.isBuy);
      formValues.value[TradeField.BaseAmount] = quantityAndOrderType.quantity;
      updateAmount({ isBaseAmount: true });
    }
    function onOrderbookPriceClick(priceAndOrderType) {
      if (formValues.value[TradeField.TradingType] === TradeExecutionType.LimitFill || formValues.value[TradeField.TradingType] === TradeExecutionType.StopLimit) {
        formValues.value[TradeField.LimitPrice] = priceAndOrderType.price;
        updateAmount({ isBaseAmount: true });
      }
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingFormInputs = __nuxt_component_0;
      const _component_PartialsTradingFormInputError = __nuxt_component_1;
      const _component_PartialsTradingDerivativesTradingOrderLeverage = __nuxt_component_2;
      const _component_PartialsTradingFormAdvancedSettings = __nuxt_component_3;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_PartialsTradingFormInputs, _mergeProps({
          amountStep: __props.amountStep,
          baseAvailableBalance: __props.baseAvailableBalance,
          feeRate: __props.feeRate,
          fees: __props.fees,
          isBaseAmount: __props.isBaseAmount,
          isBuy: __props.isBuy,
          isSpot,
          lastTradedPrice: __props.lastTradedPrice,
          market: __props.market,
          maxAmountOnOrderbook: __props.maxAmountOnOrderbook,
          maxReduceOnly: __props.maxReduceOnly,
          orderTypeReduceOnly: __props.orderTypeReduceOnly,
          position: __props.position,
          priceStep: __props.priceStep,
          quoteAvailableBalance: __props.quoteAvailableBalance
        }, { "onUpdate:amount": updateAmount }), null, 16),
        _createVNode(_component_PartialsTradingFormInputError, _normalizeProps(_guardReactiveProps({
          availableBalanceError: __props.availableBalanceError,
          baseAvailableBalance: __props.baseAvailableBalance,
          quoteAvailableBalance: __props.quoteAvailableBalance,
          initialMinMarginRequirementError: __props.initialMinMarginRequirementError,
          isBuy: __props.isBuy,
          isSpot,
          orderTypeReduceOnly: __props.orderTypeReduceOnly,
          markPriceThresholdError: __props.markPriceThresholdError,
          maxAmountOnOrderbook: __props.maxAmountOnOrderbook,
          maxReduceOnly: __props.maxReduceOnly
        })), null, 16),
        _withDirectives(_createVNode(_component_PartialsTradingDerivativesTradingOrderLeverage, _mergeProps({ class: "mt-6" }, {
          executionPrice: __props.executionPrice,
          isBuy: __props.isBuy,
          market: __props.market,
          worstPriceWithSlippage: __props.worstPriceWithSlippage,
          leverageFieldName: _unref(TradeField).Leverage
        }), null, 16), [
          [
            _vShow,
            !__props.orderTypeReduceOnly && !isSpot && __props.market.subType !== _unref(MarketType).BinaryOptions
          ]
        ]),
        _createVNode(_component_PartialsTradingFormAdvancedSettings, _mergeProps({
          formValues: _unref(formValues),
          isSpot,
          reduceOnlyDisabled: !__props.showReduceOnly
        }, { "onUpdate:amount": updateAmount }), null, 16)
      ]);
    };
  }
});
