"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "text-2xs font-semibold text-red-500",
  "data-cy": "trading-page-error-text-content"
};
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 2 };
const _hoisted_5 = { key: 3 };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { tradeErrorMessages } from "@/app/client/utils/validation/trade";
export default /* @__PURE__ */ _defineComponent({
  __name: "InputError",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    orderTypeReduceOnly: Boolean,
    availableBalanceError: Boolean,
    markPriceThresholdError: Boolean,
    initialMinMarginRequirementError: Boolean,
    baseAvailableBalance: {
      type: Object,
      default: ZERO_IN_BASE
    },
    maxAmountOnOrderbook: {
      type: Object,
      required: true
    },
    maxReduceOnly: {
      type: Object,
      default: void 0
    },
    quoteAvailableBalance: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const formValues = useFormValues();
    const formErrors = useFormErrors();
    const slippageError = computed(
      () => [
        tradeErrorMessages.slippageExceed(),
        tradeErrorMessages.slippageTooHigh(),
        tradeErrorMessages.slippageTooLow()
      ].find((error2) => Object.values(formErrors.value).includes(error2))
    );
    const error = computed(() => {
      const [error2] = Object.values(formErrors.value);
      if (error2 && error2.includes(slippageError.value)) {
        return "";
      }
      return error2;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("span", _hoisted_1, [
          _unref(error) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(error)), 1)) : __props.availableBalanceError ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.insufficient_balance")), 1)) : __props.initialMinMarginRequirementError ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.order_insufficient_margin")), 1)) : __props.markPriceThresholdError ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.mark_price_invalid")), 1)) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});
