import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Numeric.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex-1" };
const _hoisted_2 = { class: "text-xs font-semibold text-gray-200 mb-2" };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInWei } from "@injectivelabs/utils";
import { formatAmountToAllowableAmount } from "@injectivelabs/sdk-ts";
import {
  TradeField,
  TradeExecutionType
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "BaseAmount",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    amountStep: {
      type: String,
      required: true
    },
    baseAmountFieldName: {
      type: String,
      required: true
    },
    baseAvailableBalance: {
      type: Object,
      default: ZERO_IN_BASE
    },
    market: {
      type: Object,
      required: true
    },
    orderbookOrders: {
      type: Array,
      required: true
    }
  },
  emits: ["update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const formValues = useFormValues();
    const { hasTriggerPrice, tradingTypeStopMarket } = useDerivativeFormFormatter(formValues);
    const orderbookQuantity = computed(
      () => props.orderbookOrders.reduce((totalAmount, { quantity }) => {
        const { baseToken } = props.market;
        return totalAmount.plus(
          props.isSpot ? new BigNumberInWei(quantity).toBase(baseToken.decimals) : new BigNumberInWei(quantity)
        );
      }, ZERO_IN_BASE)
    );
    const { value: baseAmount, setValue: setBaseAmountValue } = useStringField({
      name: props.baseAmountFieldName,
      rule: "",
      dynamicRule: computed(() => {
        const rules = [
          `minBaseAmount:${new BigNumberInWei(props.market.minQuantityTickSize).toBase().toFixed()}`
        ];
        if (props.market.quantityTensMultiplier >= 1) {
          rules.push(
            `quantityTensMultiplier:${props.market.quantityTensMultiplier}`
          );
        }
        const formIsStopMarketAndHasNoTriggerPrice = tradingTypeStopMarket.value && !hasTriggerPrice.value;
        if (!formIsStopMarketAndHasNoTriggerPrice) {
          rules.push(`integer:${TradeField.BaseAmount}`);
        }
        if (!props.isBuy && props.isSpot) {
          rules.push(`insufficientBalance:${props.baseAvailableBalance}`);
        }
        const canSubmitHigherThanOrderbook = [
          TradeExecutionType.LimitFill,
          TradeExecutionType.StopLimit,
          TradeExecutionType.StopMarket
        ].includes(formValues.value[TradeField.TradingType]);
        if (!canSubmitHigherThanOrderbook) {
          rules.push(`maxOrderbookLiquidity:${orderbookQuantity.value.toFixed()}`);
        }
        return rules.join("|");
      })
    });
    function onBaseAmountChange(baseAmount2) {
      formValues.value[TradeField.ProportionalPercentage] = 0;
      emit("update:amount", { amount: baseAmount2 || "0", isBaseAmount: true });
    }
    function onBaseAmountBlur(baseAmount2 = "") {
      if (props.market.quantityTensMultiplier < 1) {
        return;
      }
      const formattedAmount = formatAmountToAllowableAmount(
        baseAmount2 || 0,
        props.market.quantityTensMultiplier
      );
      setBaseAmountValue(formattedAmount);
      emit("update:amount", { amount: formattedAmount || "0", isBaseAmount: true });
    }
    return (_ctx, _cache) => {
      const _component_AppInputNumeric = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppInputNumeric, {
          modelValue: _unref(baseAmount),
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event) => _isRef(baseAmount) ? baseAmount.value = $event : null),
            onBaseAmountChange
          ],
          "max-decimals": __props.market ? __props.market.quantityDecimals : 6,
          placeholder: __props.amountStep,
          step: __props.amountStep,
          min: "0",
          onBlur: onBaseAmountBlur
        }, {
          context: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("trade.amount")), 1)
          ]),
          addon: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(__props.market.baseToken.symbol.toUpperCase()), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "max-decimals", "placeholder", "step"])
      ]);
    };
  }
});
