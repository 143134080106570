import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Market/Stats/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/InsufficientGasInner.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Balances/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Card/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Trading/Market/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Trading/Market/Chart/Index.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Partials/Trading/Sidebar/Index.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/Modals/MarketBeta.vue";
import { default as __nuxt_component_8 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_9 } from "/opt/build/repo/components/Partials/Account/Bridge.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "min-h-lg h-full-flex"
};
const _hoisted_2 = { class: "w-full px-1 h-market-info flex-none" };
const _hoisted_3 = { class: "flex-1 grid grid-cols-6 lg:grid-cols-12 gap-1 p-1 h-full" };
const _hoisted_4 = {
  class: "col-span-6 lg:col-span-3 4xl:col-span-3",
  "data-cy": "trading-side-component"
};
const _hoisted_5 = {
  key: "market-trading-panel",
  class: "flex-col flex-wrap h-full w-full hidden lg:flex space-y-1"
};
const _hoisted_6 = {
  key: 0,
  class: "bg-gray-1000 rounded-lg mb-1 p-6"
};
const _hoisted_7 = { class: "h-full-flex" };
const _hoisted_8 = { class: "w-full flex-none" };
const _hoisted_9 = { class: "grid grid-cols-6 lg:grid-cols-12" };
const _hoisted_10 = { class: "col-span-6 lg:col-span-4 4xl:col-span-3 z-30" };
const _hoisted_11 = { class: "w-full lg:hidden mt-2" };
const _hoisted_12 = { class: "px-6 pt-2" };
const _hoisted_13 = { class: "flex-1 grid grid-cols-6 lg:grid-cols-12 gap-1 p-1 z-[50] lg:z-[20] absolute w-full h-screen-excluding-header-and-market-info top-market-info pointer-events-none" };
import { Status, StatusType } from "@injectivelabs/utils";
import { betaMarketSlugs } from "@/app/data/market";
import {
  getDefaultSpotMarketRouteParams,
  getDefaultPerpetualMarketRouteParams
} from "@/app/utils/market";
import {
  Modal,
  TradingLayout
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Layout",
  props: {
    isSpot: Boolean,
    hardcodedSlug: {
      type: String,
      default: void 0
    }
  },
  emits: ["loaded"],
  setup(__props, { emit }) {
    const props = __props;
    const router = useRouter();
    const appStore = useAppStore();
    const spotStore = useSpotStore();
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const exchangeStore = useExchangeStore();
    const derivativeStore = useDerivativeStore();
    const { params } = useRoute();
    const { $onError } = useNuxtApp();
    const slug = props.hardcodedSlug || Object.values(params)[0] || "";
    const showMarketList = ref(false);
    const status = reactive(new Status(StatusType.Loading));
    const fetchStatus = reactive(new Status(StatusType.Loading));
    const market = ref(void 0);
    const marketIsBeta = computed(() => betaMarketSlugs.includes(slug));
    onMounted(() => {
      Promise.all([
        exchangeStore.fetchTradingRewardsCampaign(),
        exchangeStore.fetchFeeDiscountAccountInfo(),
        ...[props.isSpot ? spotStore.init() : derivativeStore.init()]
      ]).then(() => {
        if (betaMarketSlugs.includes(slug)) {
          modalStore.openModal({ type: Modal.MarketBeta });
        }
        const marketBySlug = getMarketBySlug();
        if (!marketBySlug) {
          const defaultRoute = props.isSpot ? getDefaultSpotMarketRouteParams() : getDefaultPerpetualMarketRouteParams();
          router.push(defaultRoute);
        } else {
          market.value = marketBySlug;
          emit("loaded", marketBySlug);
        }
      }).catch($onError).finally(() => {
        status.setIdle();
        fetchStatus.setIdle();
      });
    });
    onUnmounted(() => props.isSpot ? spotStore.reset() : derivativeStore.reset());
    onWalletConnected(() => {
      if (market.value) {
        emit("loaded", market.value);
      }
    });
    const summary = computed(() => {
      const marketSummaries = props.isSpot ? spotStore.marketsSummary : derivativeStore.marketsSummary;
      return marketSummaries.find(
        (m) => m.marketId === market.value.marketId
      );
    });
    function getMarketBySlug() {
      const markets = props.isSpot ? spotStore.markets : derivativeStore.markets;
      return markets.find((m) => m.slug.toLowerCase() === slug.toLowerCase());
    }
    function close() {
      showMarketList.value = false;
    }
    function toggleMarketList() {
      showMarketList.value = !showMarketList.value;
    }
    watch(
      () => walletStore.isUserWalletConnected,
      (isConnected) => {
        if (!isConnected) {
          fetchStatus.setLoading();
        }
      }
    );
    return (_ctx, _cache) => {
      const _component_PartialsTradingMarketStats = __nuxt_component_0;
      const _component_CommonInsufficientGasInner = __nuxt_component_1;
      const _component_PartialsTradingBalances = __nuxt_component_2;
      const _component_CommonCard = __nuxt_component_3;
      const _component_PartialsTradingMarket = __nuxt_component_4;
      const _component_PartialsTradingMarketChart = __nuxt_component_5;
      const _component_PartialsTradingSidebar = __nuxt_component_6;
      const _component_ModalsMarketBeta = __nuxt_component_7;
      const _component_AppHocLoading = __nuxt_component_8;
      const _component_PartialsAccountBridge = __nuxt_component_9;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        (_openBlock(), _createBlock(_component_AppHocLoading, {
          key: _ctx.$route.fullPath,
          status: _unref(status),
          class: "h-full"
        }, {
          default: _withCtx(() => [
            _unref(market) && _unref(summary) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PartialsTradingMarketStats, _mergeProps({
                  summary: _unref(summary),
                  market: _unref(market),
                  expanded: _unref(showMarketList)
                }, { "onMarketsList:toggle": toggleMarketList }), null, 16)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_CommonCard, { "no-padding": "" }, {
                      default: _withCtx(() => [
                        _unref(fetchStatus).isIdle() && _unref(walletStore).isUserWalletConnected && !_unref(bankStore).hasEnoughInjForGas ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_CommonInsufficientGasInner)
                        ])) : (_openBlock(), _createBlock(_component_PartialsTradingBalances, {
                          key: 1,
                          market: _unref(market)
                        }, null, 8, ["market"]))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_CommonCard, {
                      "no-padding": "",
                      class: "px-6 py-4 rounded-xl relative grow"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass({
                            invisible: _unref(showMarketList)
                          })
                        }, [
                          _renderSlot(_ctx.$slots, "trading-form")
                        ], 2)
                      ]),
                      _: 3
                    })
                  ])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-span-6 lg:col-span-9 4xl:col-span-9 max-h-screen-excluding-header-and-market-info", {
                    "-order-1": _unref(appStore).userState.tradingLayout === _unref(TradingLayout).Right
                  }])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_CommonCard, {
                        tight: "",
                        class: "relative"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_PartialsTradingMarket, { market: _unref(market) }, null, 8, ["market"])
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(["col-span-6 lg:col-span-8 4xl:col-span-9", {
                                "-order-1": _unref(appStore).userState.tradingLayout === _unref(TradingLayout).Right
                              }])
                            }, [
                              _createVNode(_component_PartialsTradingMarketChart, {
                                market: _unref(market),
                                class: "hidden lg:block"
                              }, null, 8, ["market"])
                            ], 2)
                          ])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_11, [
                        _renderSlot(_ctx.$slots, "trading-panel"),
                        _createVNode(_component_PartialsTradingBalances, { market: _unref(market) }, null, 8, ["market"]),
                        _createVNode(_component_CommonCard, { class: "mt-1" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_12, [
                              _renderSlot(_ctx.$slots, "trading-form")
                            ])
                          ]),
                          _: 3
                        })
                      ])
                    ]),
                    _renderSlot(_ctx.$slots, "orders")
                  ])
                ], 2)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createVNode(_component_PartialsTradingSidebar, {
                  key: "market-selection",
                  market: _unref(market),
                  onClose: close
                }, null, 8, ["market"]), [
                  [_vShow, _unref(showMarketList)]
                ])
              ]),
              _renderSlot(_ctx.$slots, "modals"),
              _unref(marketIsBeta) ? (_openBlock(), _createBlock(_component_ModalsMarketBeta, { key: 0 })) : _createCommentVNode("", true)
            ])) : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["status"])),
        _createVNode(_component_PartialsAccountBridge)
      ], 64);
    };
  }
});
