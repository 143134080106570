import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Common/Balances/PeggyUsdcConvert.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/UserNotConnectedNote.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/App/Panel/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between" };
const _hoisted_2 = { class: "text-xs text-gray-500 flex items-center" };
const _hoisted_3 = {
  key: 0,
  class: "mt-4 relative"
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { class: "text-xs text-gray-500" };
const _hoisted_6 = { key: 1 };
const _hoisted_7 = {
  key: 0,
  class: "flex justify-between items-center text-xs mb-4"
};
const _hoisted_8 = { class: "text-gray-500" };
const _hoisted_9 = { class: "font-mono text-white" };
const _hoisted_10 = { class: "flex justify-between items-center text-xs" };
const _hoisted_11 = { class: "text-gray-500" };
const _hoisted_12 = { class: "flex gap-2" };
const _hoisted_13 = { class: "font-mono text-white" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import {
  BigNumberInBase,
  BigNumberInWei,
  Status,
  StatusType
} from "@injectivelabs/utils";
import {
  BridgeBusEvents,
  WalletConnectStatus
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const bankStore = useBankStore();
    const walletStore = useWalletStore();
    const { $onError } = useNuxtApp();
    const isSpot = props.market.type === MarketType.Spot;
    const status = reactive(new Status(StatusType.Loading));
    const { accountBalancesWithToken } = useBalance();
    const baseTradingBalance = computed(() => {
      if (!isSpot) {
        return void 0;
      }
      return accountBalancesWithToken.value.find(
        (balance) => balance.denom.toLowerCase() === props.market.baseDenom.toLowerCase()
      );
    });
    const quoteTradingBalance = computed(() => {
      return accountBalancesWithToken.value.find(
        (balance) => balance.denom.toLowerCase() === props.market.quoteDenom.toLowerCase()
      );
    });
    const hasTradingAccountBalances = computed(() => {
      var _a, _b;
      const minOrderPrice = new BigNumberInBase(1).shiftedBy(
        -props.market.priceDecimals
      );
      const baseTradingBalanceInBase = new BigNumberInWei(
        ((_a = baseTradingBalance.value) == null ? void 0 : _a.availableMargin) || 0
      ).toBase(props.market.baseToken.decimals);
      const quoteTradingBalanceInBase = new BigNumberInWei(
        ((_b = quoteTradingBalance.value) == null ? void 0 : _b.availableMargin) || 0
      ).toBase(props.market.quoteToken.decimals);
      if (props.market.type === MarketType.Derivative) {
        return quoteTradingBalanceInBase.gt(minOrderPrice);
      }
      return quoteTradingBalanceInBase.gt(minOrderPrice) || baseTradingBalanceInBase.gt(minOrderPrice);
    });
    const baseTradingBalanceToFormat = computed(() => {
      var _a;
      return new BigNumberInWei(((_a = baseTradingBalance.value) == null ? void 0 : _a.availableMargin) || "0").toBase(props.market.baseToken.decimals).toFormat(props.market.quantityDecimals, BigNumberInBase.ROUND_DOWN);
    });
    const quoteTradingBalanceToFormat = computed(() => {
      var _a;
      return new BigNumberInWei(((_a = quoteTradingBalance.value) == null ? void 0 : _a.availableMargin) || "0").toBase(props.market.quoteToken.decimals).toFormat(props.market.priceDecimals, BigNumberInBase.ROUND_DOWN);
    });
    onWalletConnected(() => {
      status.setLoading();
      Promise.all([
        bankStore.fetchAccountPortfolio(),
        bankStore.streamBankBalance()
      ]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    function handleDeposit() {
      const token = isSpot ? props.market.baseToken : props.market.quoteToken;
      useEventBus(BridgeBusEvents.Deposit).emit(token);
    }
    return (_ctx, _cache) => {
      const _component_NuxtLink = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_PartialsCommonBalancesPeggyUsdcConvert = __nuxt_component_2;
      const _component_AppHocLoading = __nuxt_component_3;
      const _component_CommonUserNotConnectedNote = __nuxt_component_4;
      const _component_AppPanel = __nuxt_component_5;
      return _openBlock(), _createBlock(_component_AppPanel, { class: "w-full" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("marketPage.assets")), 1),
              _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_NuxtLink, {
                key: 0,
                to: { name: "account" },
                class: "text-blue-500 text-2xs font-semibold"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("marketPage.account")), 1)
                ]),
                _: 1
              })) : _createCommentVNode("", true)
            ]),
            _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_AppHocLoading, {
                "show-loading": _unref(status).isLoading() || _unref(walletStore).walletConnectStatus === _unref(WalletConnectStatus).connecting
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    !_unref(hasTradingAccountBalances) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("marketPage.noTradingBalance")), 1),
                      _createVNode(_component_AppButton, {
                        class: "w-full rounded bg-blue-500 text-blue-900 mt-4",
                        onClick: handleDeposit
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.deposit")), 1)
                        ]),
                        _: 1
                      })
                    ])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      isSpot ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("trade.available_asset", {
                          asset: __props.market.baseToken.symbol
                        })), 1),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(baseTradingBalanceToFormat)), 1)
                      ])) : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("trade.available_asset", {
                          asset: __props.market.quoteToken.symbol
                        })), 1),
                        _createElementVNode("div", _hoisted_12, [
                          __props.market ? (_openBlock(), _createBlock(_component_PartialsCommonBalancesPeggyUsdcConvert, {
                            key: 0,
                            market: __props.market
                          }, null, 8, ["market"])) : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(quoteTradingBalanceToFormat)), 1)
                        ])
                      ])
                    ]))
                  ])
                ]),
                _: 1
              }, 8, ["show-loading"])
            ])) : (_openBlock(), _createBlock(_component_CommonUserNotConnectedNote, {
              key: 1,
              cta: ""
            }))
          ])
        ]),
        _: 1
      });
    };
  }
});
