import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-xs cursor-pointer" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "text-sm font-semibold" };
import { aggregationList } from "@/app/data/aggregation";
export default /* @__PURE__ */ _defineComponent({
  __name: "AggregationSelector",
  props: {
    aggregation: {
      type: String,
      required: true
    },
    minTick: {
      type: String,
      required: true
    },
    maxTick: {
      type: String,
      default: void 0
    }
  },
  emits: ["update:aggregation"],
  setup(__props, { emit }) {
    const props = __props;
    const aggregation = computed({
      get: () => props.aggregation,
      set: (latestAggregation) => {
        emit("update:aggregation", latestAggregation);
      }
    });
    const filteredList = computed(() => {
      if (props.maxTick) {
        const startIndex = aggregationList.findIndex(
          ({ value }) => value === props.maxTick
        );
        const endIndex = aggregationList.findIndex(
          ({ value }) => value === props.minTick
        );
        return aggregationList.slice(startIndex, endIndex + 1);
      }
      const index = aggregationList.findIndex(
        ({ value }) => value === props.minTick
      );
      return aggregationList.slice(Math.max(index - 2, 0), index + 1);
    });
    return (_ctx, _cache) => {
      const _component_AppSelect = __nuxt_component_0;
      return _openBlock(), _createBlock(_component_AppSelect, {
        modelValue: _unref(aggregation),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(aggregation) ? aggregation.value = $event : null),
        options: _unref(filteredList),
        "wrapper-class": "h-6 text-gray-400"
      }, {
        default: _withCtx(({ selected }) => [
          _createElementVNode("div", _hoisted_1, [
            selected ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(selected.display), 1)) : _createCommentVNode("", true)
          ])
        ]),
        option: _withCtx(({ option }) => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(option.display), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "options"]);
    };
  }
});
