import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "p-4 bg-gray-700 rounded-xl flex flex-col flex-wrap text-xs min-w-2xs"
};
const _hoisted_2 = { class: "flex justify-between items-center mb-2" };
const _hoisted_3 = { class: "font-bold" };
const _hoisted_4 = { class: "flex justify-between items-center mb-2" };
const _hoisted_5 = { class: "font-bold" };
const _hoisted_6 = { class: "flex justify-between items-center" };
const _hoisted_7 = { class: "font-bold" };
const _hoisted_8 = { class: "flex items-center" };
import { UI_MINIMAL_ABBREVIATION_FLOOR } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "SummaryPopup",
  props: {
    summary: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const { valueToString: averagePriceToString } = useBigNumberFormatter(
      computed(() => props.summary.total.dividedBy(props.summary.quantity)),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    return (_ctx, _cache) => {
      const _component_AppNumber = __nuxt_component_0;
      return __props.summary ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.average_price")) + ":", 1),
          _createElementVNode("span", null, "\u2248 " + _toDisplayString(_unref(averagePriceToString)), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.total_volume_in_quote", {
            symbol: __props.market.baseToken.symbol
          })) + ": ", 1),
          _createElementVNode("span", null, [
            _createVNode(_component_AppNumber, {
              decimals: __props.market.quantityDecimals,
              number: props.summary.quantity,
              "abbreviation-floor": _unref(UI_MINIMAL_ABBREVIATION_FLOOR),
              "no-grouping": ""
            }, null, 8, ["decimals", "number", "abbreviation-floor"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("trade.total_volume_in_base", {
            symbol: __props.market.quoteToken.symbol
          })) + ": ", 1),
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_AppNumber, {
              decimals: __props.market.priceDecimals,
              number: props.summary.total,
              "no-grouping": ""
            }, null, 8, ["decimals", "number"])
          ])
        ])
      ])) : _createCommentVNode("", true);
    };
  }
});
