import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = { class: "max-w-full flex-1 pr-5" };
const _hoisted_3 = { class: "absolute right-0 top-0" };
const _hoisted_4 = { key: 0 };
export default /* @__PURE__ */ _defineComponent({
  __name: "Drawer",
  setup(__props) {
    const isOpen = ref(true);
    function handleToggle() {
      isOpen.value = !isOpen.value;
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
          class: "group flex justify-end w-full cursor-pointer relative",
          onClick: _withModifiers(handleToggle, ["stop"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "header")
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseIcon, {
              name: "caret-down",
              class: _normalizeClass(["text-gray-500 group-hover:text-gray-200 w-4 h-4", {
                "transform rotate-180": _unref(isOpen)
              }])
            }, null, 8, ["class"])
          ])
        ], 8, _hoisted_1),
        _unref(isOpen) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])) : _createCommentVNode("", true)
      ]);
    };
  }
});
