import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Market/Orderbook/LayoutSelector.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Market/Orderbook/AggregationSelector.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Market/Chart/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Trading/Market/Orderbook/Header.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Trading/Market/Orderbook/Index.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Partials/Trading/Market/Trades/Index.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "lg:p-3" };
const _hoisted_2 = { class: "flex items-center justify-between flex-wrap" };
const _hoisted_3 = { class: "flex items-center gap-4 px-4 py-3 lg:pt-0 lg:px-0" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = {
  key: 2,
  class: "lg:hidden"
};
const _hoisted_7 = { class: "rounded-lg orderbook-h relative bg-gray-1000" };
const _hoisted_8 = { key: 0 };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { Status, StatusType } from "@injectivelabs/utils";
import { TradeExecutionSide } from "@injectivelabs/ts-types";
import {
  customAggregations,
  getDecimalPlaceFromValue
} from "@/app/data/aggregation";
import { UI_DEFAULT_AGGREGATION_DECIMALS_STRING } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const FilterList = {
      Orderbook: "Orderbook",
      Trades: "Trades",
      Charts: "MarketChart"
    };
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const { width } = useWindowSize();
    const isSpot = props.market.type === MarketType.Spot;
    const activeType = ref(FilterList.Orderbook);
    const maxTick = ref(void 0);
    const status = reactive(new Status(StatusType.Loading));
    const minTick = ref(UI_DEFAULT_AGGREGATION_DECIMALS_STRING);
    const aggregation = ref(UI_DEFAULT_AGGREGATION_DECIMALS_STRING);
    onMounted(() => {
      const { marketId } = props.market;
      Promise.all(
        isSpot ? [
          spotStore.fetchOrderbook(marketId),
          spotStore.fetchTrades({
            marketId,
            executionSide: TradeExecutionSide.Taker
          })
        ] : [
          derivativeStore.fetchOrderbook(marketId),
          derivativeStore.fetchTrades({
            marketId,
            executionSide: TradeExecutionSide.Taker
          })
        ]
      ).catch($onError).finally(() => {
        status.setIdle();
        onInit();
      });
    });
    watchDebounced(
      width,
      (newWidth) => {
        activeType.value = newWidth < 1024 ? FilterList.Charts : FilterList.Orderbook;
      },
      { debounce: 100, immediate: true }
    );
    function onInit() {
      if (props.market && props.market.minQuantityTickSize) {
        const minTickSize = getDecimalPlaceFromValue(props.market.minQuantityTickSize.toString()) || UI_DEFAULT_AGGREGATION_DECIMALS_STRING;
        minTick.value = minTickSize;
        const customAggregation = customAggregations[props.market.ticker];
        if (!customAggregation) {
          return;
        }
        if (customAggregation.minTick) {
          minTick.value = customAggregation.minTick;
        }
        maxTick.value = customAggregation.maxTick || void 0;
        aggregation.value = customAggregation.default || minTickSize;
      }
    }
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      const _component_PartialsTradingMarketOrderbookLayoutSelector = __nuxt_component_1;
      const _component_PartialsTradingMarketOrderbookAggregationSelector = __nuxt_component_2;
      const _component_PartialsTradingMarketChart = __nuxt_component_3;
      const _component_PartialsTradingMarketOrderbookHeader = __nuxt_component_4;
      const _component_PartialsTradingMarketOrderbook = __nuxt_component_5;
      const _component_PartialsTradingMarketTrades = __nuxt_component_6;
      const _component_AppHocLoading = __nuxt_component_7;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (displayType) => {
              return _openBlock(), _createBlock(_component_AppSelectButton, {
                key: `display-type-${displayType}`,
                modelValue: _unref(activeType),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
                value: displayType
              }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-xs leading-4 tracking-widest cursor-pointer uppercase", [
                      active ? "text-gray-200 hover:text-gray-100 uppercase" : "text-gray-500 hover:text-gray-200"
                    ]])
                  }, [
                    displayType === FilterList.Orderbook ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.orderbook")), 1)) : _createCommentVNode("", true),
                    displayType === FilterList.Trades ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.trades")), 1)) : _createCommentVNode("", true),
                    displayType === FilterList.Charts ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("trade.chart")), 1)) : _createCommentVNode("", true)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["modelValue", "value"]);
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex px-4", { "py-1": _unref(activeType) === FilterList.Orderbook }])
          }, [
            _unref(activeType) === FilterList.Orderbook ? (_openBlock(), _createBlock(_component_PartialsTradingMarketOrderbookLayoutSelector, { key: 0 })) : _createCommentVNode("", true),
            _unref(activeType) === FilterList.Orderbook ? (_openBlock(), _createBlock(_component_PartialsTradingMarketOrderbookAggregationSelector, {
              key: 1,
              aggregation: _unref(aggregation),
              "onUpdate:aggregation": _cache[1] || (_cache[1] = ($event) => _isRef(aggregation) ? aggregation.value = $event : null),
              class: "ml-auto h-6",
              "min-tick": _unref(minTick),
              "max-tick": _unref(maxTick)
            }, null, 8, ["aggregation", "min-tick", "max-tick"])) : _createCommentVNode("", true)
          ], 2),
          _createVNode(_component_AppHocLoading, {
            class: "h-full",
            status: _unref(status)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["rounded-lg h-full", {
                  "overflow-y-auto": _unref(activeType) === FilterList.Trades,
                  "orderbook-h-inner": _unref(activeType) === FilterList.Orderbook
                }])
              }, [
                _withDirectives(_createVNode(_component_PartialsTradingMarketChart, {
                  market: __props.market,
                  class: "lg:hidden"
                }, null, 8, ["market"]), [
                  [_vShow, _unref(activeType) === FilterList.Charts]
                ]),
                _unref(activeType) === FilterList.Orderbook ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_PartialsTradingMarketOrderbookHeader, { market: __props.market }, null, 8, ["market"]),
                  _createVNode(_component_PartialsTradingMarketOrderbook, {
                    aggregation: Number(_unref(aggregation)),
                    market: __props.market
                  }, null, 8, ["aggregation", "market"])
                ])) : _createCommentVNode("", true),
                _unref(activeType) === FilterList.Trades ? (_openBlock(), _createBlock(_component_PartialsTradingMarketTrades, {
                  key: 1,
                  market: __props.market
                }, null, 8, ["market"])) : _createCommentVNode("", true)
              ], 2)
            ]),
            _: 1
          }, 8, ["status"])
        ])
      ]);
    };
  }
});
