"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-auto px-0 lg:px-3" };
const _hoisted_2 = { class: "text-gray-500 mb-1 w-full lg:text-right text-xs flex items-center lg:justify-between whitespace-nowrap" };
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketInfo",
  props: {
    title: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = _resolveComponent("CommonInfoTooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createTextVNode(_toDisplayString(__props.title) + " ", 1),
          __props.tooltip ? (_openBlock(), _createBlock(_component_CommonInfoTooltip, {
            key: 0,
            class: "ml-2",
            tooltip: __props.tooltip
          }, null, 8, ["tooltip"])) : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]);
    };
  }
});
