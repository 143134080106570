import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Form/OrderError.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
import {
  MarketType
} from "@injectivelabs/sdk-ui-ts";
import { UI_DEFAULT_MAX_NUMBER_OF_ORDERS } from "@/app/utils/constants";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import { tradeErrorMessages } from "@/app/client/utils/validation/trade";
import { Modal, TradeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "OrderSubmit",
  props: {
    isBuy: Boolean,
    hasBaseAmount: Boolean,
    highDeviation: Boolean,
    maxOrdersError: Boolean,
    hasTriggerPrice: Boolean,
    orderTypeReduceOnly: Boolean,
    availableBalanceError: Boolean,
    markPriceThresholdError: Boolean,
    initialMinMarginRequirementError: Boolean,
    executionPrice: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    status: {
      type: Object,
      required: true
    }
  },
  emits: ["submit:request", "form:submit"],
  setup(__props, { emit }) {
    const props = __props;
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const formValues = useFormValues();
    const formErrors = useFormErrors();
    const { t } = useLang();
    const { error } = useNotifications();
    const isSpot = props.market.type === MarketType.Spot;
    const hasError = computed(() => {
      if (!isSpot && (props.initialMinMarginRequirementError || props.markPriceThresholdError)) {
        return true;
      }
      const filteredErrors = Object.keys(formErrors.value).filter(
        (key) => ![TradeField.SlippageTolerance].includes(key)
      );
      return filteredErrors.length > 0 || props.availableBalanceError || props.maxOrdersError;
    });
    const triggerPriceEqualsMarkPrice = computed(
      () => Object.values(formErrors.value).includes(
        tradeErrorMessages.triggerPriceEqualsMarkPrice()
      )
    );
    const {
      isConditionalOrder,
      tradingTypeStopMarket,
      tradingTypeLimit: derivativeTradingTypeLimit,
      tradingTypeMarket: derivativeTradingTypeMarket
    } = useDerivativeFormFormatter(formValues);
    const {
      tradingTypeLimit: spotTradingTypeLimit,
      tradingTypeMarket: spotTradingTypeMarket
    } = useSpotFormFormatter(formValues);
    const tradingTypeLimit = isSpot ? spotTradingTypeLimit : derivativeTradingTypeLimit;
    const tradingTypeMarket = isSpot ? spotTradingTypeMarket : derivativeTradingTypeMarket;
    const disabled = computed(() => {
      const commonErrors = hasError.value || !props.hasBaseAmount || !bankStore.hasEnoughInjForGas || !walletStore.isUserWalletConnected;
      if (commonErrors) {
        return true;
      }
      const isPerpConditionalOrderWithoutTriggerPrice = !isSpot && isConditionalOrder.value && !props.hasTriggerPrice;
      if (isPerpConditionalOrderWithoutTriggerPrice) {
        return true;
      }
      const isPerpConditionalOrderWithIncorrectTriggerPrice = !isSpot && isConditionalOrder.value && triggerPriceEqualsMarkPrice.value;
      if (isPerpConditionalOrderWithIncorrectTriggerPrice) {
        return true;
      }
      if (props.executionPrice.lte(0) && !tradingTypeStopMarket.value) {
        return true;
      }
      return false;
    });
    function handleSubmit() {
      trackPlaceOrder();
      if (!walletStore.isUserWalletConnected) {
        return error({ title: t("connect.pleaseConnectToYourWallet") });
      }
      if (hasError.value) {
        return error({ title: t("trade.error_in_form") });
      }
      if (props.maxOrdersError) {
        return error({
          title: t("trade.you_can_only_have_max_orders", {
            number: UI_DEFAULT_MAX_NUMBER_OF_ORDERS
          })
        });
      }
      emit("submit:request");
    }
    function trackPlaceOrder() {
      const actualSlippageTolerance = tradingTypeMarket.value ? formValues.value[TradeField.SlippageTolerance] : "";
      amplitudeTracker.submitClickPlaceOrderTrackEvent({
        market: props.market.slug,
        marketType: props.market.subType,
        reduceOnly: props.orderTypeReduceOnly,
        slippageTolerance: actualSlippageTolerance,
        amount: formValues.value[TradeField.BaseAmount],
        leverage: formValues.value[TradeField.Leverage],
        orderType: formValues.value[TradeField.OrderType],
        limitPrice: formValues.value[TradeField.LimitPrice],
        tradingType: formValues.value[TradeField.TradingType],
        triggerPrice: formValues.value[TradeField.TriggerPrice],
        postOnly: tradingTypeLimit.value && formValues.value[TradeField.PostOnly]
      });
    }
    function handleConnect() {
      modalStore.openModal({ type: Modal.Connect });
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingFormOrderError = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_PartialsTradingFormOrderError, _normalizeProps(_guardReactiveProps({
          highDeviation: __props.highDeviation
        })), null, 16),
        !_unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 0,
          lg: "",
          class: "bg-blue-500 text-blue-900 font-semibold w-full",
          onClick: handleConnect
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("connect.connect")), 1)
          ]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_AppButton, {
          key: 1,
          lg: "",
          status: __props.status,
          disabled: _unref(disabled),
          class: _normalizeClass([{
            "hover:text-green-900 bg-green-500 text-green-800": !_unref(disabled) && !_unref(hasError) && __props.isBuy,
            "hover:text-red-900 bg-red-500 text-red-800": !_unref(disabled) && !_unref(hasError) && !__props.isBuy
          }, "w-full font-sembold shadow-none"]),
          "data-cy": "trading-page-execute-button",
          onClick: handleSubmit
        }, {
          default: _withCtx(() => [
            isSpot ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.isBuy ? _ctx.$t("trade.buy") : _ctx.$t("trade.sell")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.isBuy ? _unref(t)("trade.buyLong") : _unref(t)("trade.sellShort")), 1))
          ]),
          _: 1
        }, 8, ["status", "disabled", "class"]))
      ]);
    };
  }
});
