import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Numeric.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "mb-6" };
const _hoisted_2 = { class: "text-xs font-semibold text-gray-200 mb-2" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
import { formatPriceToAllowablePrice } from "@injectivelabs/sdk-ts";
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { TradeField } from "@/types";
import {
  DEFAULT_MAX_PRICE_BAND_DIFFERENCE,
  DEFAULT_MIN_PRICE_BAND_DIFFERENCE
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Price",
  props: {
    isBuy: Boolean,
    isSpot: Boolean,
    isBaseAmount: Boolean,
    tradingTypeLimit: Boolean,
    tradingTypeStopLimit: Boolean,
    lastTradedPrice: {
      type: Object,
      required: true
    },
    orderbookOrders: {
      type: Array,
      default: () => []
    },
    priceFieldName: {
      type: String,
      required: true
    },
    priceStep: {
      type: String,
      required: true
    },
    market: {
      type: Object,
      default: () => null
    }
  },
  emits: ["update:amount"],
  setup(__props, { emit }) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const spotStore = useSpotStore();
    const formValues = useFormValues();
    const { markPrice } = useDerivativeLastPrice(computed(() => props.market));
    const { hasTriggerPrice, tradingTypeStopMarket } = useDerivativeFormFormatter(formValues);
    const highestBuy = computed(() => {
      const buys = props.isSpot ? spotStore.buys : derivativeStore.buys;
      const [buy] = buys || [];
      return new BigNumberInWei(buy ? buy.price : 0).toBase(
        props.isSpot ? props.market.quoteToken.decimals - props.market.baseToken.decimals : props.market.quoteToken.decimals
      );
    });
    const lowestSell = computed(() => {
      const sells = props.isSpot ? spotStore.sells : derivativeStore.sells;
      const [sell] = sells || [];
      return new BigNumberInWei(sell ? sell.price : 0).toBase(
        props.isSpot ? props.market.quoteToken.decimals - props.market.baseToken.decimals : props.market.quoteToken.decimals
      );
    });
    const middlePrice = computed(
      () => highestBuy.value.plus(lowestSell.value).dividedBy(2)
    );
    const acceptableMax = computed(
      () => middlePrice.value.times(DEFAULT_MAX_PRICE_BAND_DIFFERENCE.div(100))
    );
    const cappedAcceptableMin = computed(() => {
      const acceptableMin = middlePrice.value.times(
        new BigNumberInBase(1).minus(DEFAULT_MIN_PRICE_BAND_DIFFERENCE.div(100))
      );
      const minTickPrice = new BigNumberInBase(
        new BigNumberInBase(1).shiftedBy(-props.market.priceDecimals)
      );
      return acceptableMin.gt(0) ? acceptableMin : minTickPrice;
    });
    const topOfOrderbookPrice = computed(() => {
      const [order] = props.orderbookOrders;
      if (!order) {
        return "";
      }
      return new BigNumberInWei(order.price).toBase(
        props.isSpot ? props.market.quoteToken.decimals - props.market.baseToken.decimals : props.market.quoteToken.decimals
      ).toFixed();
    });
    const { value: price, setValue: setPriceField } = useStringField({
      name: props.priceFieldName,
      rule: "",
      dynamicRule: computed(() => {
        const rules = [`integer:${props.priceFieldName}`];
        if (props.priceFieldName === TradeField.TriggerPrice) {
          rules.push(`triggerPriceEqualsMarkPrice:${markPrice.value}`);
        }
        if (props.priceFieldName === TradeField.LimitPrice && formValues.value[TradeField.PostOnly]) {
          rules.push(
            `invalidPostOnlyPrice:${topOfOrderbookPrice.value},${props.isBuy}`
          );
        }
        const formIsStopMarketAndHasNoTriggerPrice = tradingTypeStopMarket && !hasTriggerPrice;
        if (formIsStopMarketAndHasNoTriggerPrice) {
          rules.push(`integer:${TradeField.TriggerPrice}}`);
        }
        if (props.tradingTypeLimit && props.lastTradedPrice.gt(0) && middlePrice.value.gt(0) && new BigNumberInBase(formValues.value[TradeField.LimitPrice]).gt(0)) {
          rules.push(
            `priceHighDeviationFromMidOrderbookPrice:${cappedAcceptableMin.value.toFixed()},${acceptableMax.value.toFixed()}`
          );
        }
        return rules.join("|");
      })
    });
    function recalculateBaseQuoteAmountValue() {
      formValues.value[TradeField.ProportionalPercentage] = 0;
      emit("update:amount", { isBaseAmount: props.isBaseAmount });
    }
    function onPriceBlur(price2 = "") {
      if (!props.market) {
        return;
      }
      const formattedPrice = formatPriceToAllowablePrice(
        price2 || 0,
        props.market.priceTensMultiplier
      );
      setPriceField(formattedPrice);
    }
    return (_ctx, _cache) => {
      const _component_AppInputNumeric = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppInputNumeric, _mergeProps({
          modelValue: _unref(price),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(price) ? price.value = $event : null)
        }, {
          placeholder: __props.priceStep,
          step: __props.priceStep,
          maxDecimals: __props.market.priceDecimals
        }, {
          min: "0",
          "onUpdate:modelValue": recalculateBaseQuoteAmountValue,
          onInput: recalculateBaseQuoteAmountValue,
          onBlur: onPriceBlur
        }), {
          context: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, [
              __props.priceFieldName === _unref(TradeField).TriggerPrice ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.trigger_price")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.limit_price")), 1))
            ])
          ]),
          addon: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(__props.market.quoteToken.symbol), 1)
          ]),
          _: 1
        }, 16, ["modelValue"])
      ]);
    };
  }
});
